import { OpenAPIClientAxios } from 'openapi-client-axios';
import type { Client as AgentClient } from '@/types/schemas/agent-schema';
import authorizationMiddleware from '@/api/middleware/authorization';
import languageMiddleware from '@/api/middleware/language';
import dataExtractMiddleware from '@/api/middleware/dataExtract';

let apiInstance: {
  agentApi: AgentClient;
} | null = null;

const initClients = async () => {
  const agentApiDefinition = new OpenAPIClientAxios({
    // Files in the public directory are served at the root path.
    definition: 'swagger/agent-swagger.json',
    withServer: {
      url: import.meta.env.VITE_APP_PROXY_TARGET,
    },
  });
  const agentApi = await agentApiDefinition.init<AgentClient>();

  configureMiddleware(agentApi);
  return {
    agentApi,
  };
};

const configureMiddleware = (client: AgentClient) => {
  client.interceptors.response.use(dataExtractMiddleware);
  client.interceptors.request.use(authorizationMiddleware);
  client.interceptors.request.use(languageMiddleware);
};

export default async function getApiInstance() {
  if (!apiInstance) {
    apiInstance = await initClients();
  }

  return apiInstance;
}
