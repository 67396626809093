type BaseBookingItem = {
  amount: number | undefined;
  currency: string | undefined;
  description: string | undefined;
};

export type PassengerBookingItem = BaseBookingItem & {
  firstName: string | undefined;
  lastName: string | undefined;
  passengerIndex: number;
};

export type TripBookingItem = PassengerBookingItem & {
  originStopName: string | undefined;
  destinationStopName: string | undefined;
};

export type NonTripOfferBookingItem = PassengerBookingItem & {
  validFrom: string | undefined;
};

export type NonTripOfferFeeBookingItem = BaseBookingItem;

export type BookingItem =
  | PassengerBookingItem
  | TripBookingItem
  | NonTripOfferBookingItem
  | NonTripOfferFeeBookingItem;

export enum Flow {
  purchase = 'purchase',
  manageBooking = 'manage-booking',
}
