import type { BackgroundTheme, BrandColors, HSLString } from '@/types/color';

export const setGlobalStyleProperties = (
  styleProperties: Record<string, string>,
  backgroundTheme: BackgroundTheme
) => {
  Object.entries(styleProperties).forEach(([key, value]) => {
    document.documentElement.style.setProperty(`--${key}`, value);
  });

  if (backgroundTheme === 'dark') {
    document.body.classList.add('text-white');
  } else {
    document.body.classList.add('text-dark');
  }
};

export const hexToHSL = (hex: string): HSLString => {
  // Convert hex to RGB
  const r: number = parseInt(hex.slice(1, 3), 16) / 255;
  const g: number = parseInt(hex.slice(3, 5), 16) / 255;
  const b: number = parseInt(hex.slice(5, 7), 16) / 255;

  // Find min and max RGB components
  const cmin: number = Math.min(r, g, b);
  const cmax: number = Math.max(r, g, b);
  const delta: number = cmax - cmin;

  let h: number, s: number, l: number;

  // Calculate hue
  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);
  if (h < 0) h += 360;

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Convert to percentages
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return `${h}, ${s}%, ${l}%`;
};

export const createBrandColorTint = (
  hex: string,
  lightnessChange: number,
  lightnessMode: 'add' | 'subtract' | 'set' = 'set',
  saturationSubtractionAmount?: number
): HSLString => {
  const hslString = hexToHSL(hex);
  const [h, s, l] = hslString.match(/\d+/g)!.map(Number);
  let newColorLightness: number;

  const newColorSaturation: number =
    saturationSubtractionAmount !== undefined
      ? Math.max(0, Math.min(100, s - saturationSubtractionAmount))
      : s;

  switch (lightnessMode) {
    case 'add':
      newColorLightness = Math.floor(Math.min(100, l + lightnessChange));
      break;
    case 'subtract':
      newColorLightness = Math.floor(Math.max(0, l - lightnessChange));
      break;
    case 'set':
    default:
      newColorLightness = Math.floor(
        Math.max(0, Math.min(100, lightnessChange))
      );
      break;
  }

  return `${h}, ${newColorSaturation}%, ${newColorLightness}%`;
};

export const getCalculatedStyleProperties = (
  brandColors: BrandColors
): Record<string, HSLString> => ({
  primary: hexToHSL(brandColors.primary),
  'primary-dark': createBrandColorTint(brandColors.primary, 15, 'subtract'),
  'primary-medium': createBrandColorTint(brandColors.primary, 5, 'add', 10),
  'primary-light': createBrandColorTint(brandColors.primary, 95, 'set'),
  'primary-pale': createBrandColorTint(brandColors.primary, 97, 'set'),

  secondary: hexToHSL(brandColors.secondary),
  'secondary-light': createBrandColorTint(brandColors.secondary, 95, 'set'),
  'secondary-pale': createBrandColorTint(brandColors.secondary, 97, 'set'),

  accent: hexToHSL(brandColors.accent),
  'accent-light': createBrandColorTint(brandColors.accent, 73, 'set'),
  'accent-pale': createBrandColorTint(brandColors.accent, 83, 'set'),

  neutral: createBrandColorTint(brandColors.dark, 45, 'set', 75),
  'neutral-medium': createBrandColorTint(brandColors.dark, 60, 'set', 80),
  'neutral-light': createBrandColorTint(brandColors.dark, 80, 'set', 80),
  'neutral-pale': createBrandColorTint(brandColors.dark, 93, 'set', 75),

  'greyscale-800': hexToHSL('#474747'),
  'greyscale-100': hexToHSL('#F9F9F9'),

  'success-border': hexToHSL('#008E4C'),
  'success-text': hexToHSL('#007941'),
  'success-surface': hexToHSL('#EBF6F1'),
  'success-surface-hover': hexToHSL('#E0F1EA'),

  'warning-border': hexToHSL('#E57A00'),
  'warning-surface': hexToHSL('#FDF4EB'),
  'warning-surface-hover': hexToHSL('#FCEFE0'),

  'error-border': hexToHSL('#E00004'),
  'error-text': hexToHSL('#BE0003'),
  'error-surface': hexToHSL('#FDEBEB'),
  'error-surface-hover': hexToHSL('#FBE0E1'),

  'info-border': hexToHSL('#006DCC'),
  'info-text': hexToHSL('#005DAD'),
  'info-surface': hexToHSL('#EBF3FB'),
  'info-surface-hover': hexToHSL('#E0EDF9'),

  dark: hexToHSL(brandColors.dark),
});

export const getBackgroundColor = (
  backgroundTheme: BackgroundTheme,
  appColors: Record<string, HSLString>
): HSLString => {
  switch (backgroundTheme) {
    case 'dark':
      return appColors.dark;
    case 'pale':
      return appColors['neutral-pale'];
    case 'light':
      return appColors['primary-light'];
    case 'second':
      return appColors['secondary-light'];
    case 'white':
    default:
      return hexToHSL('#FFF');
  }
};
