import { createSelector, selector } from '@/store/utils';

const selectManageBookingBooking = selector(
  (state) => state.manageBooking.booking
);
export const manageBookingBookingSelector = createSelector(
  selectManageBookingBooking
);

const selectSelectedAdmissions = selector(
  (state) => state.manageBooking.refund.selectedAdmissions
);
export const selectedAdmissionsSelector = createSelector(
  selectSelectedAdmissions
);

const selectRefundDetails = selector(
  (state) => state.manageBooking.refund.details
);
export const refundDetailsSelector = createSelector(selectRefundDetails);

const selectRefundSummary = selector(
  (state) => state.manageBooking.refund.details
);
export const refundSummarySelector = createSelector(selectRefundSummary);
