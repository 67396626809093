import { createLoadingSelector } from '@/store/utils';
import {
  getInboundJourneys,
  getOutboundJourneys,
  getNonTripOffers,
  payWithExternalPayment,
  addAncillary,
  removeAncillary,
  createTicketBooking,
  createNonTripOfferBooking,
  getPurchaseBookingById,
} from '@/features/purchase/purchaseActions';
import {
  initiateRefund,
  deleteRefundOffer,
} from '@/features/manage-booking/manageBookingActions';
import { getManageBookingBookingByNumberAndEmail } from '@/features/manage-booking/manageBookingService';
import {
  getBrandConfiguration,
  getCurrencyConfiguration,
} from '@/features/configuration/configurationActions';

export const outboundJourneysLoadingSelector =
  createLoadingSelector(getOutboundJourneys);

export const inboundJourneysLoadingSelector =
  createLoadingSelector(getInboundJourneys);

export const nonTripOffersLoadingSelector =
  createLoadingSelector(getNonTripOffers);

export const purchaseBookingLoadingSelector = createLoadingSelector(
  createTicketBooking,
  createNonTripOfferBooking,
  getPurchaseBookingById
);

export const addAncillaryLoadingSelector = createLoadingSelector(addAncillary);

export const checkoutLoadingSelector = createLoadingSelector(
  addAncillary,
  removeAncillary,
  getPurchaseBookingById,
  payWithExternalPayment
);

export const manageBookingBookingLoadingSelector = createLoadingSelector(
  getManageBookingBookingByNumberAndEmail
);

export const successLoadingSelector = createLoadingSelector(
  getPurchaseBookingById
);

export const refundLoadingSelector = createLoadingSelector(
  initiateRefund,
  deleteRefundOffer
);

export const configurationLoadingSelector = createLoadingSelector(
  getCurrencyConfiguration,
  getBrandConfiguration
);
