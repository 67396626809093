import { Icons } from '@/components/icons/index';

export const PlaceIcon = ({
  placeIcon,
}: {
  placeIcon: 'seat' | 'compartment' | 'bed';
}) => {
  switch (placeIcon) {
    case 'compartment':
      return <Icons.compartment height={12} width={12} />;
    case 'bed':
      return <Icons.bed height={12} width={12} />;
    case 'seat':
    default:
      return <Icons.seat height={12} width={12} />;
  }
};
