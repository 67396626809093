import { useMemo } from 'react';
import type { BookingDTO } from '@/types/dto';

const useBookingTotalPrice = (booking: BookingDTO) => {
  const bookedTripsAmount = useMemo(
    () =>
      booking?.bookedTrips?.reduce(
        (bookedTripsAcc, { bookedOffers }) =>
          bookedTripsAcc +
          (bookedOffers || []).reduce(
            (bookedOffersAcc, { admissions }) =>
              bookedOffersAcc +
              (admissions || []).reduce(
                (admissionsAcc, { price, reservations, ancillaries }) =>
                  admissionsAcc +
                  (price?.amount || 0) +
                  (reservations || []).reduce(
                    (reservationsAcc, { price }) =>
                      reservationsAcc + (price?.amount || 0),
                    0
                  ) +
                  (ancillaries || []).reduce(
                    (ancillariesAcc, { price, reservations }) =>
                      ancillariesAcc +
                      (price?.amount || 0) +
                      (reservations || []).reduce(
                        (reservationsAcc, { price }) =>
                          reservationsAcc + (price?.amount || 0),
                        0
                      ),
                    0
                  ),
                0
              ),
            0
          ),
        0
      ) || 0,
    [booking?.bookedTrips]
  );

  const nonTripOffersAmount = useMemo(
    () =>
      booking?.nonTripOffers?.reduce(
        (offersAcc, { admissions }) =>
          offersAcc +
          (admissions || []).reduce(
            (admissionsAcc, { price, reservations }) =>
              admissionsAcc +
              (price?.amount || 0) +
              (reservations || []).reduce(
                (reservationsAcc, { price }) =>
                  reservationsAcc + (price?.amount || 0),
                0
              ),
            0
          ),
        0
      ) || 0,
    [booking?.nonTripOffers]
  );

  return bookedTripsAmount + nonTripOffersAmount;
};

export default useBookingTotalPrice;
