import type { FC } from 'react';
import { cn } from '@/utils/cn';

interface HalfCircleProps {
  classNames?: string;
  direction: 'left' | 'right' | 'up' | 'down';
}
const HalfCircle: FC<HalfCircleProps> = ({ classNames, direction }) => {
  return (
    <div
      className={cn('bg-dark', classNames, {
        'ml-[-2px] h-6 w-3 rounded-r-full': direction === 'right',
        'mr-[-2px] h-6 w-3 rounded-l-full': direction === 'left',
        'mt-[-2px] h-3 w-6 rounded-t-full': direction === 'up',
        'mb-[-2px] h-3 w-6 rounded-b-full': direction === 'down',
      })}
    />
  );
};

export default HalfCircle;
