import type { Components } from '@/types/schemas/agent-schema';
import { formatTime } from '@/utils/date-time';
import { Icons } from '@/components/icons';
import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';

interface LegIntermediateStopProps {
  stop: Components.Schemas.TurnitOsdmTranslatorApiCommonConnectorModelsConnectorIntermediateStop;
}

const LegIntermediateStop: FC<LegIntermediateStopProps> = ({ stop }) => {
  const departureTime = formatTime(stop.departureTime);
  const arrivalTime = formatTime(stop.arrivalTime);

  return (
    <div className="relative grid grid-cols-[1fr_64px_64px] text-dark">
      <Icons.destination
        width={10}
        height={10}
        className="absolute -left-[31px] top-0.5 bg-white text-neutral-light"
      />
      <Typography variant="body2" className="text-wrap">
        {stop.name}
      </Typography>
      <Typography variant="body2" className="justify-self-center text-dark">
        {departureTime}
      </Typography>
      <Typography variant="body2" className="justify-self-center">
        {arrivalTime}
      </Typography>
    </div>
  );
};

export default LegIntermediateStop;
