import type { RefundOffersDTO, AdmissionDTO, BookingDTO } from '@/types/dto';
import getApiInstance from '@/api';
import { createAsyncThunk } from '@/store/utils';
import { createAction } from '@reduxjs/toolkit';
import { runInSequence } from '@/utils/runInSequence';

export const resetSelection = createAction(
  'manageBooking/refund/resetSelection'
);

export const toggleLeg = createAction(
  'manageBooking/refund/toggleLeg',
  function prepare(
    legId: string,
    selectedAdmissions: Record<string, AdmissionDTO[]>,
    admissions: AdmissionDTO[] | []
  ) {
    const legAdmissions = selectedAdmissions[legId] || [];
    const areAllSelected = legAdmissions.length === admissions?.length;

    return {
      payload: {
        legId,
        admissions: areAllSelected ? [] : admissions,
      },
    };
  }
);

export const toggleAdmission = createAction(
  'manageBooking/refund/toggleAdmission',
  function prepare(
    legId: string,
    selectedAdmissions: Record<string, AdmissionDTO[]>,
    admission: AdmissionDTO
  ) {
    const legAdmissions = selectedAdmissions[legId] || [];
    const updatedLegAdmissions = legAdmissions.some(
      (a) => a.id === admission?.id
    )
      ? legAdmissions.filter((a) => a.id !== admission?.id)
      : [...legAdmissions, admission];

    return {
      payload: {
        legId,
        admissions: updatedLegAdmissions,
      },
    };
  }
);

export const initiateRefund = createAsyncThunk<RefundOffersDTO, void>(
  'manageBooking/refund/initiateRefund',
  async (_, { getState }) => {
    const state = getState();
    const { selectedAdmissions } = state.manageBooking.refund;
    const bookingId = state.manageBooking.booking?.id;

    const fulfillmentIds = Object.values(selectedAdmissions)
      .flat()
      .filter((admission) => admission.status !== 'REFUNDED')
      .flatMap((admission) => [
        ...admission.fulfillments!.map((f) => f.id!),
        ...(admission.ancillaries?.flatMap(
          (ancillary) => ancillary.fulfillments?.map((f) => f.id!) ?? []
        ) ?? []),
      ]);

    const api = (await getApiInstance()).agentApi;
    const response = await api.RefundOffers_InitiateRefund(
      { bookingId: bookingId! },
      {
        fulfillmentIds: [fulfillmentIds[0], ...fulfillmentIds.slice(1)],
      }
    );
    return response.data;
  }
);

export const deleteRefundOffer = createAsyncThunk<void, void>(
  'manageBooking/refund/deleteRefundOffer',
  async (_, { getState }) => {
    const state = getState();
    const bookingId = state.manageBooking.booking?.id;

    const api = (await getApiInstance()).agentApi;

    await runInSequence(
      state.manageBooking.refund.details!.refundOffers!.map(
        (offer) => () =>
          api.RefundOffers_DeleteRefund({
            bookingId: bookingId!,
            refundOfferId: offer.id!,
          })
      )
    );
  }
);

export const confirmRefundOffer = createAsyncThunk<void, void>(
  'manageBooking/refund/confirmRefundOffer',
  async (_, { getState }) => {
    const state = getState();
    const bookingId = state.manageBooking.booking?.id;
    const api = (await getApiInstance()).agentApi;

    await runInSequence(
      state.manageBooking.refund.details!.refundOffers!.map(
        (offer) => () =>
          api.RefundOffers_ConfirmRefund(
            { bookingId: bookingId!, refundOfferId: offer.id! },
            { status: 'CONFIRMED' }
          )
      )
    );
  }
);

export const getManageBookingBookingById = createAsyncThunk<BookingDTO, string>(
  'manageBooking/booking/getManageBookingBookingById',
  async (id) => {
    const api = (await getApiInstance()).agentApi;

    return (await api.Bookings_GetBooking(id)).data;
  }
);

export const clearManageBookingBooking = createAction(
  'manageBooking/booking/clearManageBookingBooking'
);
