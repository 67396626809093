import type { InternalAxiosRequestConfig } from 'axios';

/**
 * @description Prefixes requests with the language that the user has chosen
 * @example /offers -> /en/offers
 * @param config
 */
export default function languageMiddleware(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  const prefix = config.baseURL ?? import.meta.env?.VITE_APP_API_TARGET;
  const isUrlWithLanguageInHeader =
    prefix === import.meta.env?.VITE_APP_AUTH_URL;

  const language = 'en'; // i18n.language ?? 'en'
  config.baseURL = isUrlWithLanguageInHeader ? prefix : `${prefix}/${language}`;

  return config;
}
