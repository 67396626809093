import { createAsyncThunk } from '@/store/utils';
import type { BackgroundTheme, BrandColors } from '@/types/color';
import {
  DEFAULT_BACKGROUND_THEME,
  DEFAULT_BRAND_COLORS,
  DEFAULT_RADIUS,
} from '@/utils/consts';
import type { PoSConfigurationDTO } from '@/types/dto';
import getApiInstance from '@/api';
import {
  getBackgroundColor,
  getCalculatedStyleProperties,
  setGlobalStyleProperties,
} from '@/utils/color';

export const getConfiguration = createAsyncThunk<void, void>(
  'configuration/getConfiguration',
  (_, { getState, dispatch }) => {
    const {
      configuration: { currency, brand },
    } = getState();

    if (!currency.name) {
      dispatch(getCurrencyConfiguration());
    }

    if (!brand.brandColors || !brand.backgroundTheme) {
      dispatch(getBrandConfiguration());
    }
  }
);

export const getBrandConfiguration = createAsyncThunk<
  {
    brandColors: BrandColors;
    backgroundTheme: BackgroundTheme;
  },
  void
>('configuration/getBrandConfiguration', async () => {
  //TODO: Implement actual API call once the endpoint exists
  const newBrandColors = DEFAULT_BRAND_COLORS;
  const newBackgroundTheme = DEFAULT_BACKGROUND_THEME;

  const calculatedStyleProperties =
    getCalculatedStyleProperties(newBrandColors);
  const newBackgroundColor = getBackgroundColor(
    newBackgroundTheme,
    calculatedStyleProperties
  );

  setGlobalStyleProperties(
    {
      ...calculatedStyleProperties,
      background: newBackgroundColor,
      radius: DEFAULT_RADIUS,
    },
    newBackgroundTheme
  );

  return {
    brandColors: DEFAULT_BRAND_COLORS,
    backgroundTheme: DEFAULT_BACKGROUND_THEME,
  };
});

export const getCurrencyConfiguration = createAsyncThunk<
  NonNullable<PoSConfigurationDTO['currencies']>[number],
  void
>('configuration/getCurrencyConfiguration', async () => {
  const api = (await getApiInstance()).agentApi;
  const response: PoSConfigurationDTO = (
    await api.PointOfSaleConfiguration_GetSalesPointConfiguration()
  ).data;

  return response.currencies![0];
});
