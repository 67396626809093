import { cn } from '@/utils/cn';
import React from 'react';
import {
  FormControl,
  FormItem,
  useFormField,
} from '@/components/primitives/Form';
import { Input } from '@/components/primitives/Input';

export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode;
  name: string;
  hideLabel?: boolean;
}

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  ({ label, name, hideLabel, className, required, ...props }, ref) => {
    const { error, formItemId } = useFormField();

    return (
      <FormItem>
        <FormControl
          label={
            <>
              {label}
              {required && (
                <span
                  className={cn('ml-0.5 text-neutral', {
                    'text-error-text': error,
                  })}
                >
                  *
                </span>
              )}
            </>
          }
          hideLabel={hideLabel}
        >
          <Input
            {...props}
            ref={ref}
            name={name}
            id={formItemId}
            className={cn(className, {
              ['border-error-text hover:border-error-text focus-visible:border-error-text focus-visible:ring-error-text']:
                error,
            })}
            aria-invalid={!!error}
          />
        </FormControl>
      </FormItem>
    );
  }
);

InputField.displayName = 'InputField';
