import type { PaymentTypeDTO } from '@/types/dto';
import type { FC } from 'react';
import { Input } from '@/components/primitives/Input';
import { Label } from '@/components/primitives/Label';
import { Typography } from '@/components/primitives/Typography';

interface PaymentMethodRowProps {
  method: PaymentTypeDTO;
  key: number;
  onClick: () => void;
  isActive: boolean;
}

const PaymentMethodRow: FC<PaymentMethodRowProps> = ({
  method,
  onClick,
  isActive,
}) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <Input
        id={method.id}
        type="radio"
        value={method.id}
        onClick={() => onClick()}
        checked={isActive}
        className="h-5 w-5 accent-primary"
        readOnly
      />
      <Label htmlFor={method.id} className="">
        <Typography variant="body1" className="text-dark">
          {method.name}
        </Typography>
      </Label>
    </div>
  );
};

export default PaymentMethodRow;
