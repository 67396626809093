import { createAsyncThunk } from '@/store/utils';
import type { BookingDTO } from '@/types/dto';
import getApiInstance from '@/api';

export const getManageBookingBookingByNumberAndEmail = createAsyncThunk<
  BookingDTO,
  {
    bookingNumber: string;
    email: string;
  }
>(
  'manageBooking/getSingleBookingByNumberAndEmail',
  async ({ bookingNumber, email }) => {
    const api = (await getApiInstance()).agentApi;

    const searchResults = (
      await api.BookingsSearch_SearchBookingsCustom(null, {
        reference: bookingNumber,
        purchaserPassengerEmail: email,
        concessionVoucherSearchType: 'ALL',
      })
    ).data.bookingsSearchResults;

    if (searchResults?.length !== 1) {
      throw new Error(
        `Expected 1 search result, found ${searchResults?.length}.`
      );
    }

    const booking = searchResults[0];

    return (await api.Bookings_GetBooking({ bookingId: booking.id! })).data;
  }
);
