import type { FC } from 'react';
import { useCallback } from 'react';
import BookingItemRow from '@/components/booking-summary/BookingItemRow';
import { TransText } from '@/i18n/trans/text';
import HighlightedRow from '@/components/booking-summary/HighlightedRow';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import type {
  BookingItem,
  NonTripOfferFeeBookingItem,
  NonTripOfferBookingItem,
  TripBookingItem,
} from '@/types/booking';

interface BookingItemsProps {
  outboundTickets: Array<TripBookingItem>;
  inboundTickets: Array<TripBookingItem>;
  ancillaries: Array<TripBookingItem>;
  nonTripOffers: Array<NonTripOfferBookingItem>;
  nonTripOfferFees: Array<NonTripOfferFeeBookingItem>;
}

const BookingItems: FC<BookingItemsProps> = ({
  outboundTickets,
  inboundTickets,
  ancillaries,
  nonTripOffers,
  nonTripOfferFees,
}) => {
  const renderRow = useCallback(
    (bookingItems: Array<BookingItem>, icon?: keyof typeof Icons) =>
      bookingItems.map((data, key) => (
        <BookingItemRow {...data} icon={icon ?? 'ticket'} key={key} />
      )),
    []
  );

  return (
    <>
      <HighlightedRow>
        <div className="flex gap-2">
          <Icons.travelPass className="h-5 w-5 text-primary" />
          <Typography variant="body1-bold">
            <TransText i18nKey="bookingItems" />
          </Typography>
        </div>
      </HighlightedRow>
      {renderRow(outboundTickets)}
      {renderRow(inboundTickets)}
      {renderRow(ancillaries)}
      {renderRow(nonTripOffers)}
      {renderRow(nonTripOfferFees, 'coin')}
    </>
  );
};

export default BookingItems;
