import type { BackgroundTheme } from '@/types/color';
import type { PaymentTypeDTO } from '@/types/dto';

export const DEFAULT_BRAND_COLORS = {
  dark: '#010845',
  primary: '#003FBF',
  secondary: '#8619FF',
  accent: '#FFF250',
};

export const DEFAULT_BACKGROUND_THEME: BackgroundTheme = 'dark';
export const DEFAULT_RADIUS = '8px';

export enum STEP {
  PreSearchSubmit,
  OutboundJourney,
  OutboundOffer,
  InboundJourney,
  InboundOffer,
  NonTripOffer,
  Checkout,
}

export const paymentMethods: Array<PaymentTypeDTO> = [
  {
    id: 'SALES_POINT_PAYMENT_TYPE.EXTERNAL_3RD_PARTY',
    name: 'External',
    isNegativePaymentAllowed: false,
  },
];

export enum TransportType {
  HIGH_SPEED_TRAIN = 'HIGH_SPEED_TRAIN',
  INTERCITY_REGIONAL = 'INTERCITY_REGIONAL',
  LONG_DISTANCE = 'LONG_DISTANCE',
  LOCAL_TRAIN = 'LOCAL_TRAIN',
  LOCAL_BUS = 'LOCAL_BUS',
  TRAIN = 'TRAIN',
  BUS = 'BUS',
}
