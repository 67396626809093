import { type FC, useEffect, useState } from 'react';
import { Loadable } from '@/components/Loadable';
import { getConsents } from '@/features/purchase/purchaseService';
import type { ConsentsValues } from '@/utils/zod/schema';
import { useFieldArray, type UseFormReturn } from 'react-hook-form';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import sanitizeMarkup from '@/utils/sanitizeMarkup';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/primitives/Form';
import { Input } from '@/components/primitives/Input';
import { cn } from '@/utils/cn';

interface ConsentsProps {
  consentsForm: UseFormReturn<ConsentsValues>;
}

const Consents: FC<ConsentsProps> = ({ consentsForm }) => {
  const [loading, setLoading] = useState(true);
  const {
    control,
    setValue,
    formState: { errors },
  } = consentsForm;
  const {
    fields: consents,
    append,
    update,
  } = useFieldArray({
    control,
    name: 'consents',
  });

  useEffect(() => {
    const fetchConsents = async () => {
      const consents = await getConsents();
      setValue('consents', []);
      consents.items?.forEach(({ id, isMandatory, description }) => {
        append({
          isMandatory,
          description,
          checked: false,
          id,
        });
      });
      setLoading(false);
    };

    fetchConsents();
  }, [append, setValue]);

  return (
    <Loadable loading={loading}>
      <Form {...consentsForm}>
        <div className="grid w-full grid-cols-1 gap-2 tablet:grid-cols-2 desktop:grid-cols-3">
          {consents.map((consent, index) => {
            const markup = consent.description || '';
            const hasError = errors.consents?.[index]?.checked;

            return (
              <FormField
                control={control}
                name={`consents.${index}.checked`}
                key={index}
                render={() => (
                  <FormItem>
                    <FormControl label={null}>
                      <div className="flex items-center gap-2">
                        <Input
                          type="checkbox"
                          className={cn('h-6 w-6', 'border-error-text')}
                          checked={consent.checked}
                          onChange={({ target }) =>
                            update(index, {
                              ...consent,
                              checked: target.checked,
                            })
                          }
                        />
                        <FormLabel>
                          <div
                            key={consent.id}
                            className="flex flex-col items-baseline gap-1"
                          >
                            {markup ? (
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: sanitizeMarkup(markup),
                                }}
                                variant="body1"
                                className={cn(
                                  'prose text-dark prose-p:my-0 prose-p:text-dark prose-a:text-blue-600 prose-a:no-underline',
                                  {
                                    'text-error-text': hasError,
                                  }
                                )}
                              />
                            ) : (
                              <Typography
                                variant="body1"
                                className={cn('text-dark', {
                                  'text-error-text': hasError,
                                })}
                              >
                                <TransText i18nKey="undefinedConsentDescription" />
                              </Typography>
                            )}
                            {!consent.isMandatory && (
                              <span>
                                <Typography
                                  variant="body2"
                                  className="text-neutral"
                                >
                                  (<TransText i18nKey="optional" />)
                                </Typography>
                              </span>
                            )}
                          </div>
                        </FormLabel>
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
            );
          })}
        </div>
      </Form>
    </Loadable>
  );
};

export default Consents;
