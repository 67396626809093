import type { TransKeys } from '@/i18n/trans/index';
import { createTrans } from '@/i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransText = createTrans({
  activation: <Trans i18nKey="text.activation" defaults="Activation" />,
  addNewAncillary: (
    <Trans i18nKey="text.addNewancillary" defaults="Add new ancillary" />
  ),
  addNewAncillaryModal: (
    <Trans
      i18nKey="text.addNewAncillaryModal"
      defaults="Add new ancillary modal"
    />
  ),
  ancillaries: <Trans i18nKey="text.ancillaries" defaults="Ancillaries" />,
  ancillaryPricePerPassenger: (
    <Trans
      i18nKey="text.ancillaryPricePerPassenger"
      defaults="Ancillary price per passenger"
    />
  ),
  arrival: <Trans i18nKey="text.arrival" defaults="Arrival" />,
  back: <Trans i18nKey="text.back" defaults="Back" />,
  banks: <Trans i18nKey="text.banks" defaults="Banks" />,
  booking: <Trans i18nKey="text.booking" defaults="Booking" />,
  bookingDetails: (
    <Trans i18nKey="text.bookingDetails" defaults="Booking details" />
  ),
  bookingItems: <Trans i18nKey="text.bookingItems" defaults="Booking items" />,
  bookingNumber: (
    <Trans i18nKey="text.bookingNumber" defaults="Booking number" />
  ),
  bookingSummary: (
    <Trans i18nKey="text.bookingSummary" defaults="Booking summary" />
  ),
  cancel: <Trans i18nKey="text.cancel" defaults="Cancel" />,
  cancelBooking: (
    <Trans i18nKey="text.cancelBooking" defaults="Cancel booking" />
  ),
  changeSearchInputs: (
    <Trans
      i18nKey="text.changeSearchInputs"
      defaults="No trips found. Please change the search inputs and try again."
    />
  ),
  changeable: <Trans i18nKey="text.changeable" defaults="Changeable" />,
  checkout: <Trans i18nKey="text.checkout" defaults="Checkout" />,
  chooseAncillaryAndConfirmChoice: (
    <Trans
      i18nKey="text.chooseAncillaryAndConfirmChoice"
      defaults="Choose ancillary and confirm the choice"
    />
  ),
  chooseTripsToRefund: (
    <Trans
      i18nKey="text.chooseTripsToRefund"
      defaults="Choose trip(s) to refund"
    />
  ),
  clickHereToSearch: (
    <Trans i18nKey="text.clickHereToSearch" defaults="Click here to search" />
  ),
  close: <Trans i18nKey="text.close" defaults="Close" />,
  confirm: <Trans i18nKey="text.confirm" defaults="Confirm" />,
  continue: <Trans i18nKey="text.continue" defaults="Continue" />,
  custom: <Trans i18nKey="text.custom" defaults="Custom" />,
  departure: <Trans i18nKey="text.departure" defaults="Departure" />,
  details: <Trans i18nKey="text.details" defaults="Details" />,
  direct: <Trans i18nKey="text.direct" defaults="Direct" />,
  downloadPdf: <Trans i18nKey="text.downloadPdf" defaults="Download pdf" />,
  downloadPkpass: (
    <Trans i18nKey="text.downloadPkpass" defaults="Download pkpass" />
  ),
  editSearch: <Trans i18nKey="text.editSearch" defaults="Edit search" />,
  email: <Trans i18nKey="text.email" defaults="Email" />,
  emailUsedForBooking: (
    <Trans
      i18nKey="text.emailUsedForBooking"
      defaults="Email (used for booking)"
    />
  ),
  enterNameEmailAndPhoneNumber: (
    <Trans
      i18nKey="text.enterNameEmailAndPhoneNumber"
      defaults="Enter name, email and phone number"
    />
  ),
  enterYourBookingNumber: (
    <Trans
      i18nKey="text.enterYourBookingNumber"
      defaults="Enter your booking number"
    />
  ),
  enterYourEmailAddress: (
    <Trans
      i18nKey="text.enterYourEmailAddress"
      defaults="Enter your email address"
    />
  ),
  fareDetails: <Trans i18nKey="text.fareDetails" defaults="Fare details" />,
  findYourBooking: (
    <Trans i18nKey="text.findYourBooking" defaults="Find your booking" />
  ),
  firstName: <Trans i18nKey="text.firstName" defaults="First name" />,
  from: <Trans i18nKey="text.from" defaults="From" />,
  iAgreeWithTermsAndConditions: (
    <Trans
      i18nKey="text.iAgreeWithTermsAndConditions"
      defaults="I agree with <anchor>terms & conditions</anchor>"
    />
  ),
  inbound: <Trans i18nKey="text.inbound" defaults="Inbound" />,
  inboundTrip: <Trans i18nKey="text.inboundTrip" defaults="Inbound trip" />,
  inboundTrips: (
    <Trans
      i18nKey="text.inboundTrips"
      defaults="(1)[Inbound trip];(2-inf)[Inbound trips]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  journeyDetailsAndAvailableFares: (
    <Trans
      i18nKey="text.journeyDetailsAndAvailableFares"
      defaults="Journey details & available fares"
    />
  ),
  journeyTransfersCount: (
    <Trans
      i18nKey="text.journeyTransfersCount"
      defaults="(0)[{{count}} transfers];(1)[{{count}} transfer];(2-inf)[{{count}} transfers];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  lastName: <Trans i18nKey="text.lastName" defaults="Last name" />,
  legNotSelected: (
    <Trans i18nKey="text.legNotSelected" defaults="Leg not selected" />
  ),
  manageBooking: (
    <Trans i18nKey="text.manageBooking" defaults="Manage booking" />
  ),
  nextAvailableDate: (
    <Trans
      i18nKey="text.nextAvailableDate"
      defaults="Next available date: {{date}}"
    />
  ),
  noAncillariesSelected: (
    <Trans
      i18nKey="text.noAncillariesChosen"
      defaults="No ancillaries selected"
    />
  ),
  noTravelPassesFound: (
    <Trans
      i18nKey="text.noTravelPassesFound"
      defaults="No travel passes found"
    />
  ),
  noTripsFoundNextDate: (
    <Trans
      i18nKey="text.noTripsFoundNextDate"
      defaults="No trips found. Choose a different date or change the search inputs"
    />
  ),
  nonRefundable: (
    <Trans i18nKey="text.nonRefundable" defaults="Non-refundable" />
  ),
  notAvailable: <Trans i18nKey="text.notAvailable" defaults="Not available" />,
  notRefundable: (
    <Trans i18nKey="text.notRefundable" defaults="Non-refundable" />
  ),
  numberOfYearsOld: (
    <Trans
      i18nKey="text.numberOfYearsOld"
      defaults="(0-1)[{{count}} year old];(2-inf)[{{count}} years old];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  optional: <Trans i18nKey="text.optional" defaults="optional" />,
  orderNo: (
    <Trans i18nKey="text.orderNo" defaults="Your order number is: {{number}}" />
  ),
  other: <Trans i18nKey="text.other" defaults="Other" />,
  outbound: <Trans i18nKey="text.outbound" defaults="Outbound" />,
  outboundTrip: <Trans i18nKey="text.outboundTrip" defaults="Outbound trip" />,
  outboundTrips: (
    <Trans
      i18nKey="text.outboundTrips"
      defaults="(1)[Outbound trip];(2-inf)[Outbound trips]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  outline: <Trans i18nKey="text.outline" defaults="Outline" />,
  passengerCount: (
    <Trans
      i18nKey="text.passengerCount"
      defaults="(0)[{{count}} passengers];(1)[{{count}} passenger];(2-inf)[{{count}} passengers]"
    />
  ),
  passengerIndex: (
    <Trans
      i18nKey="text.passengerIndex"
      defaults="(0)[Passenger];(1-inf)[Passenger {{count}}];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  passengers: <Trans i18nKey="text.passengers" defaults="Passengers" />,
  passengersCount: (
    <Trans
      i18nKey="text.passengersCount"
      defaults="(0)[{{count}} passengers];(1)[{{count}} passenger];(2-inf)[{{count}} passengers];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  pay: <Trans i18nKey="text.pay" defaults="Pay" />,
  payer: <Trans i18nKey="text.payer" defaults="Payer" />,
  paymentMethod: (
    <Trans i18nKey="text.paymentMethod" defaults="Payment method" />
  ),
  phoneNumber: <Trans i18nKey="text.phoneNumber" defaults="Phone number" />,
  priceIsForAllPassengers: (
    <Trans
      i18nKey="text.priceIsForAllPassengers"
      defaults="The price covers all passengers."
    />
  ),
  purchase: <Trans i18nKey="text.purchase" defaults="Purchase" />,
  refund: <Trans i18nKey="text.refund" defaults="Refund" />,
  refundFee: <Trans i18nKey="text.refundFee" defaults="Refund fee" />,
  refundIssuedText: (
    <Trans
      i18nKey="text.refundIssuedText"
      defaults="The refund will be issued to the card that was used to pay for the booking."
    />
  ),
  refundModalDescription: (
    <Trans
      i18nKey="text.refundModalDescription"
      defaults="Modal for refunding tickets and admissions"
    />
  ),
  refundTrips: (
    <Trans
      i18nKey="text.refundTrips"
      defaults="(1)[Refund trip];(2-inf)[Refund trips]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  refundable: <Trans i18nKey="text.refundable" defaults="Refundable" />,
  refunded: <Trans i18nKey="text.refunded" defaults="Refunded" />,
  remove: <Trans i18nKey="text.remove" defaults="Remove" />,
  returnTrip: <Trans i18nKey="text.returnTrip" defaults="Return trip" />,
  returnTrips: (
    <Trans
      i18nKey="text.returnTrips"
      defaults="(1)[Return trip];(2-inf)[Return trips]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  select: <Trans i18nKey="text.select" defaults="Select" />,
  selectAncillary: (
    <Trans i18nKey="text.selectAncillary" defaults="Select ancillary" />
  ),
  selectLeg: <Trans i18nKey="text.selectLeg" defaults="Select leg" />,
  selectOutboundFare: (
    <Trans i18nKey="text.selectOutboundFare" defaults="Select outbound fare" />
  ),
  selectOutboundTrip: (
    <Trans i18nKey="text.selectOutboundTrip" defaults="Select outbound trip" />
  ),
  selectPassengers: (
    <Trans i18nKey="text.selectPassengers" defaults="Select passengers" />
  ),
  selectPassengersToRefund: (
    <Trans
      i18nKey="text.selectPassengersToRefund"
      defaults="Select passengers to refund"
    />
  ),
  selectReturnFare: (
    <Trans i18nKey="text.selectReturnFare" defaults="Select return fare" />
  ),
  selectReturnTrip: (
    <Trans i18nKey="text.selectReturnTrip" defaults="Select return trip" />
  ),
  selectTravelPass: (
    <Trans i18nKey="text.selectTravelPass" defaults="Select travel pass" />
  ),
  selected: <Trans i18nKey="text.selected" defaults="Selected" />,
  stopsCount: (
    <Trans
      i18nKey="text.stops"
      defaults="(0)[{{count}} stops];(1)[{{count}} stop];(2-inf)[{{count}} stops];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  ticketPurchased: (
    <Trans i18nKey="text.ticketPurchased" defaults="Tickets purchased" />
  ),
  ticketSent: (
    <Trans
      i18nKey="text.ticketSent"
      defaults="Your tickets have been sent to your email address."
    />
  ),
  total: <Trans i18nKey="text.total" defaults="Total" />,
  totalPrice: <Trans i18nKey="text.totalPrice" defaults="Total price" />,
  totalRefund: <Trans i18nKey="text.totalRefund" defaults="Total refund" />,
  transfer: <Trans i18nKey="text.transfer" defaults="Transfer" />,
  transferTime: <Trans i18nKey="text.transferTime" defaults="transfer time" />,

  tripLegPartIndex: (
    <Trans i18nKey="text.tripLegPartIndex" defaults="Part {{tripLegPart}}" />
  ),

  unchangeable: <Trans i18nKey="text.unchangeable" defaults="Unchangeable" />,
  undefinedConsentDescription: (
    <Trans
      i18nKey="text.undefinedConsentDescription"
      defaults="Undefined consent description"
    />
  ),
  youAreAboutToRefundFollowingItems: (
    <Trans
      i18nKey="text.youAreAboutToRefundFollowingItems"
      defaults="You are about to refund the following items:"
    />
  ),
});

export type TransTextKeys = TransKeys<typeof TransText>;
