import type { PassengerValues } from '@/utils/zod/schema';
import isEmpty from 'lodash/isEmpty';
import type { ReactNode } from 'react';
import { Fragment } from 'react';
import { TransText } from '@/i18n/trans/text';

export const getPassengerName = (
  { firstName, lastName }: Partial<PassengerValues>,
  index: number
) => {
  const fullName = [firstName, lastName].filter(Boolean).join(' ');

  return isEmpty(fullName) ? (
    <TransText i18nKey="passengerIndex" count={index + 1} />
  ) : (
    fullName
  );
};

export const getPassengerOtherDetails = ({ age }: PassengerValues) => {
  const details: Array<ReactNode> = [];

  if (age) {
    details.push(<TransText i18nKey="numberOfYearsOld" count={age} />);
  }

  return details.map((detail, index) => (
    <Fragment key={index}>
      {index > 0 && ', '}
      {detail}
    </Fragment>
  ));
};
