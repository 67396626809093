import Divider from '@/components/primitives/Divider';
import { MobileSearchWidget } from '@/components/search-widget/MobileSearchWidget';
import { RegularSearchWidget } from '@/components/search-widget/RegularSearchWidget';
import {
  purchaseBookingLoadingSelector,
  inboundJourneysLoadingSelector,
  outboundJourneysLoadingSelector,
  configurationLoadingSelector,
} from '@/features/loading/loadingSelectors';
import { useSearchWidgetContext } from '@/hooks/useSearchWidgetContext';
import { useSelector } from '@/store/utils';
import { breakpoints } from '@/utils/breakpoints';
import { DEFAULT_BRAND_COLORS } from '@/utils/consts';
import { SearchWidget } from '@turnit-ride-ui/webshop-search-widget';
import { getQueryParams } from '@turnit-ride-ui/webshop-search-widget/widget/utils/query';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { purchaseBookingIdSelector } from '@/features/purchase/purchaseSelectors';

export const SearchWidgetWrapper = () => {
  const { handleSubmit } = useSearchWidgetContext();
  const configurationLoading = useSelector(configurationLoadingSelector);
  const purchaseBookingId = useSelector(purchaseBookingIdSelector);
  const outboundJourneysLoading = useSelector(outboundJourneysLoadingSelector);
  const inboundJourneysLoading = useSelector(inboundJourneysLoadingSelector);
  const journeysLoading = useMemo(
    () => outboundJourneysLoading && inboundJourneysLoading,
    [inboundJourneysLoading, outboundJourneysLoading]
  );
  const bookingLoading = useSelector(purchaseBookingLoadingSelector);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const [isAutoSubmitted, setIsAutoSubmitted] = useState(false);
  const queryParams = useMemo(() => getQueryParams(), []);

  useEffect(() => {
    if (!configurationLoading && !purchaseBookingId) {
      const {
        origin,
        destination,
        departureDate,
        returnDate,
        passengers,
        productType,
      } = queryParams;

      if (
        origin?.value &&
        destination?.value &&
        departureDate &&
        passengers &&
        productType &&
        !isAutoSubmitted
      ) {
        handleSubmit({
          origin,
          destination,
          departureDate,
          returnDate,
          passengers,
          productType,
        });
      }

      setIsAutoSubmitted(true);
    }
  }, [
    configurationLoading,
    handleSubmit,
    isAutoSubmitted,
    purchaseBookingId,
    queryParams,
  ]);

  const searchWidget = useMemo(
    () => (
      <SearchWidget
        baseUrl={import.meta.env.VITE_APP_PROXY_TARGET}
        onSubmit={handleSubmit}
        size="sm"
        isLoading={journeysLoading || bookingLoading}
        brandColors={DEFAULT_BRAND_COLORS}
      />
    ),
    [bookingLoading, handleSubmit, journeysLoading]
  );

  return isLaptopOrBigger ? (
    <RegularSearchWidget>{searchWidget}</RegularSearchWidget>
  ) : (
    <>
      <MobileSearchWidget>{searchWidget}</MobileSearchWidget>
      <Divider />
    </>
  );
};
