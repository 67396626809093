import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import AncillaryRow from '@/components/purchase/checkout/ancillaries/AncillaryRow';
import type { BookedAncillaryDTO, LegDTO } from '@/types/dto';
import type { UseFormReturn } from 'react-hook-form';
import type { PassengerValues } from '@/utils/zod/schema';
import { TransText } from '@/i18n/trans/text';
import { useBookingAncillariesByLegId } from '@/hooks/useBookingAncillariesByLegId';
import { useSelector } from '@/store/utils';
import { purchaseBookingSelector } from '@/features/purchase/purchaseSelectors';

interface LegSectionProps {
  leg: LegDTO;
  handleRemoveAncillary: (ancillary: BookedAncillaryDTO) => void;
  passengerListForm: UseFormReturn<{
    passengers: Array<PassengerValues>;
  }>;
}

const LegSection: FC<LegSectionProps> = ({
  leg,
  handleRemoveAncillary,
  passengerListForm,
}) => {
  const booking = useSelector(purchaseBookingSelector)!;
  const ancillariesByLegId = useBookingAncillariesByLegId(booking);
  const selectedAncillaries = ancillariesByLegId[leg.id!];

  return (
    <div className="flex w-full flex-col gap-2 text-dark">
      <div className="flex w-fit flex-row items-center gap-2 pt-2">
        <Typography variant="body2-bold">{leg.originStop?.name}</Typography>
        <Icons.vectorRight />
        <Typography variant="body2-bold">
          {leg.destinationStop?.name}
        </Typography>
      </div>
      {selectedAncillaries?.length ? (
        selectedAncillaries.map((ancillary, key) => (
          <AncillaryRow
            ancillary={ancillary}
            icon={<Icons.bicycle className="h-4 w-4 text-primary" />}
            handleRemoveAncillary={handleRemoveAncillary}
            key={key}
            passengerListForm={passengerListForm}
          />
        ))
      ) : (
        <Typography variant="body2" className="text-neutral-light-foregorund">
          <TransText i18nKey="noAncillariesSelected" />
        </Typography>
      )}
    </div>
  );
};

export default LegSection;
