import type { AxiosResponse } from 'openapi-client-axios';

/**
 * @desc Flattens data.success to data if backend response is embedded into data.success object
 * @param data
 * @param response
 */
export default function dataExtractMiddleware({
  data,
  ...response
}: AxiosResponse): AxiosResponse {
  return {
    ...response,
    data: data.success ?? data,
  };
}
