const sanitizeMarkup = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  doc.querySelectorAll('*').forEach((node) => {
    node.removeAttribute('style');
    node.removeAttribute('color');
    node.removeAttribute('face');
  });

  return doc.body.innerHTML;
};

export default sanitizeMarkup;
