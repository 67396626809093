import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/utils/cn';
import { typographyVariants } from '@/components/primitives/Typography';

const badgeVariants = cva(
  cn(
    'inline-flex items-center gap-1 rounded-full border px-2 py-1 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2',
    typographyVariants({ variant: 'label' })
  ),
  {
    variants: {
      variant: {
        success: 'border-success-border bg-success-surface text-success-text',
        info: 'border-primary bg-primary-light text-primary',
        attention: 'border-warning-border bg-warning-surface text-dark',
        negative: 'border-error-border bg-error-surface text-error-text',
      },
    },
    defaultVariants: {
      variant: 'info',
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
