import { useCallback, useEffect, useRef } from 'react';
import { downloadBookingTickets } from '@/features/purchase/purchaseService';

interface UseTicketDownloadProps {
  bookingId?: string;
  downloadTimeout?: number;
}

export const useTicketDownload = ({
  bookingId,
  downloadTimeout,
}: UseTicketDownloadProps) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!bookingId) return;
    if (downloadTimeout) {
      timeoutRef.current = setTimeout(
        () => downloadBookingTickets(bookingId),
        downloadTimeout
      );
    }

    return () => clearTimeout(timeoutRef.current);
  }, [bookingId, downloadTimeout]);

  return useCallback(async () => {
    if (!bookingId) return;
    clearTimeout(timeoutRef.current);
    await downloadBookingTickets(bookingId);
  }, [bookingId]);
};
