import type { OffersWarningDTO } from '@/types/dto';
import { type JourneyDTO, type NonTripOfferDTO } from '@/types/dto';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import type { OffersWarning, WarningsByTripId } from '@/types/offer';

export const getWarningsByTripId = (
  warnings: Array<OffersWarningDTO> | undefined
) =>
  warnings?.reduce<WarningsByTripId>((map, warning) => {
    if (!warning.detail) {
      return map;
    }

    map[warning.detail] = _pick(warning, ['type', 'instance', 'code']);

    return map;
  }, {}) || {};

export const getWarning = (
  tripIds: Array<string>,
  warningsByTripId: WarningsByTripId
) => {
  const warnings = tripIds.map((tripId) => {
    return warningsByTripId[tripId];
  });

  return warnings.length
    ? getWarningMessage(warnings[0])
    : getWarningMessage({ code: 'NOT_AVAILABLE ' });
};

export const getJourneyWarning = (
  journey: JourneyDTO,
  warningsByTripId: WarningsByTripId
): string | null => {
  if (journey.lowestPrice) {
    // we assume no legs are giving warnings if lowestPrice exists on journey
    return null;
  }

  const tripIds = _map(journey.trips, 'id').filter(Boolean) as Array<string>;

  return getWarning(tripIds, warningsByTripId);
};

// should be refactored once https://youtrack.tsolutions.co/issue/BR-49131 is completed
function getWarningMessage(warning: OffersWarning | null) {
  if (!warning) {
    return null;
  }
  // TODO: Find all codes and map them to messages
  switch (warning?.code) {
    case 'SOLD_OUT':
      return 'Sold out';
    case 'NOT_ENOUGH_SEATS':
      return 'Not enough seats';
    case 'NOT_AVAILABLE':
      return 'Not available'; // fallback code given by FE
    default:
      return 'Unconfigured warning message.';
  }
}

export const getNonTripOfferWarning = (
  nonTripOffer: NonTripOfferDTO,
  warningsByTripId: WarningsByTripId
): string | null => {
  const tripIds = _map(nonTripOffer.admissionOfferParts, 'id').filter(
    Boolean
  ) as Array<string>;

  return getWarning(tripIds, warningsByTripId);
};
