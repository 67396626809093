import { useSelector } from '@/store/utils';
import {
  inboundAdditionalOfferPartsSelector,
  outboundAdditionalOfferPartsSelector,
} from '@/features/purchase/purchaseSelectors';
import { useMemo } from 'react';
import type { AdditionalOfferItem } from '@/types/offer';

export const useAvailableAncillaries = (
  legId: string | null
): Array<AdditionalOfferItem> => {
  const outboundAdditionalOfferParts = useSelector(
    outboundAdditionalOfferPartsSelector
  );
  const inboundAdditionalOfferParts = useSelector(
    inboundAdditionalOfferPartsSelector
  );
  const allAdditionalOfferParts = useMemo(
    () => [...outboundAdditionalOfferParts, ...inboundAdditionalOfferParts],
    [inboundAdditionalOfferParts, outboundAdditionalOfferParts]
  );

  return useMemo(
    () =>
      allAdditionalOfferParts.flatMap(
        ({ ancillaryOfferParts }) =>
          ancillaryOfferParts?.filter((ancillaryOffer) =>
            ancillaryOffer.legIds?.some((id) => id === legId)
          ) || []
      ),
    [allAdditionalOfferParts, legId]
  );
};
