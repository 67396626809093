import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import type { NonTripOfferBookingItem } from '@/types/booking';
import { format } from 'date-fns';
import type { FC } from 'react';

interface NonTripOfferDescriptionProps {
  nonTripOffers: Array<NonTripOfferBookingItem>;
}

const NonTripOfferDescription: FC<NonTripOfferDescriptionProps> = ({
  nonTripOffers,
}) => {
  return (
    <div className="flex flex-row gap-2 px-4 py-3">
      <Icons.transportFrom className="h-5 w-5 text-primary" />
      <div>
        <Typography variant="body1-bold">
          {nonTripOffers[0].description}
        </Typography>
        <div className="flex gap-1">
          <Typography className="text-neutral" variant="body1">
            <TransText i18nKey="activation" />
            {':'}
          </Typography>
          {nonTripOffers[0].validFrom && (
            <Typography variant="body1-bold">
              {format(nonTripOffers[0].validFrom, 'dd MMM, yyyy')}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default NonTripOfferDescription;
