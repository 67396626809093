import type { FC } from 'react';
import Divider from '@/components/primitives/Divider';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { Icons } from '@/components/icons';

interface TransferTimeProps {
  formattedTime: string | null;
  arrivalStation?: string;
  departureStation?: string;
  className?: string;
}

const TransferTime: FC<TransferTimeProps> = ({
  formattedTime,
  arrivalStation,
  departureStation,
}) => {
  const isSameStation = arrivalStation === departureStation;

  return (
    <div className="mt-1.5 flex flex-col items-center gap-2">
      <Divider className="mt-1.5 w-full" />
      <Typography variant="body2-bold" className="text-neutral-light">
        {formattedTime} <TransText i18nKey="transferTime" />
      </Typography>
      {!isSameStation && (
        <div className="flex flex-row gap-1.5">
          <Typography variant="body2">{arrivalStation}</Typography>
          <Icons.transfer />
          <Typography variant="body2">{departureStation}</Typography>
        </div>
      )}
      <Divider className="mb-1.5 w-full" />
    </div>
  );
};

export default TransferTime;
