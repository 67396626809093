import { createReducer } from '@reduxjs/toolkit';
import {
  getBrandConfiguration,
  getCurrencyConfiguration,
} from '@/features/configuration/configurationActions';
import type { BackgroundTheme, BrandColors } from '@/types/color';
import currencySymbolMap from '@/utils/currencySymbolMap';

type ConfigurationState = {
  brand: {
    brandColors: BrandColors | null;
    backgroundTheme: BackgroundTheme | null;
  };
  currency: {
    name: string | null;
    symbol: string | null;
  };
};

const initialState: ConfigurationState = {
  brand: {
    brandColors: null,
    backgroundTheme: null,
  },
  currency: {
    name: null,
    symbol: null,
  },
};

export const configurationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getBrandConfiguration.fulfilled, (state, action) => {
      state.brand = action.payload;
    })
    .addCase(getCurrencyConfiguration.fulfilled, (state, action) => {
      state.currency = {
        name: action.payload,
        symbol: currencySymbolMap[action.payload],
      };
    });
});
