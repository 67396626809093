import type { FC, ReactNode } from 'react';
import { cn } from '@turnit-ride-ui/webshop-search-widget/widget/utils/cn';

interface HighlightedRowProps {
  children: ReactNode;
  className?: string;
}

const HighlightedRow: FC<HighlightedRowProps> = ({
  children,
  className = '',
}) => (
  <div
    className={cn(
      'flex flex-row items-center justify-between bg-primary-pale px-4 py-3',
      className
    )}
  >
    {children}
  </div>
);

export default HighlightedRow;
