import type { FC, ReactNode } from 'react';

export interface LoadableProps {
  loading: boolean;
  children: ReactNode;
  overlay?: boolean;
}

export const Loadable: FC<LoadableProps> = ({ loading, children, overlay }) =>
  loading ? (
    overlay ? (
      <div className="relative">
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner />
        </div>
        <div className="pointer-events-none absolute inset-0 z-0 bg-gray-500 opacity-50" />
        <div className="">{children}</div>
      </div>
    ) : (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    )
  ) : (
    children
  );

const Spinner = () => (
  <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-primary" />
);
