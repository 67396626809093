import type { Event as SentryEvent, EventHint } from '@sentry/types';
import * as Sentry from '@sentry/react';

declare global {
  interface Window {
    __lastExceptionTime__?: number;
    __lastExceptionMsg__?: string;
  }
}

const beforeSend = (
  event: SentryEvent,
  hint: EventHint
): SentryEvent | null => {
  const exception = hint.originalException as Error | undefined;

  if (exception && exception.message) {
    const now = Date.now();

    if (
      window.__lastExceptionTime__ &&
      window.__lastExceptionMsg__ === exception.message &&
      now - window.__lastExceptionTime__ < 1000
    ) {
      return null; // If the same error occurred within a second, don't send it
    }

    window.__lastExceptionMsg__ = exception.message;
    window.__lastExceptionTime__ = now;
  }

  return event;
};

const ignoredErrors = [
  'Network Error',
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
  '$ is not defined',
  'SecurityError',
  'QuotaExceededError',
  'window.fetch is not a function',
];

export function initializeSentry() {
  if (import.meta.env.MODE !== 'production') {
    return;
  }

  const dsn = import.meta.env.VITE_APP_SENTRY_DSN;
  const tenant = import.meta.env.VITE_APP_TENANT;
  const environmentName = import.meta.env.VITE_APP_ENVIRONMENT;
  const environment = `${tenant}-${environmentName}`;

  Sentry.init({
    dsn,
    beforeSend,
    ignoreErrors: ignoredErrors,
    environment,
    release: import.meta.env.VITE_APP_VERSION,
    autoSessionTracking: false,
  });
}
