import { Typography } from '@/components/primitives/Typography';
import SuccessFailureIcon from '@/components/purchase/card/SuccessFailureIcon';
import { TransText } from '@/i18n/trans/text';
import type { FC } from 'react';

interface RefundabilityProps {
  condition: boolean;
  isOfferEnabled?: boolean;
}

const Refundability: FC<RefundabilityProps> = ({
  condition,
  isOfferEnabled = true,
}) => {
  return (
    <div className="flex flex-row items-center gap-1">
      <SuccessFailureIcon
        condition={condition}
        isOfferEnabled={isOfferEnabled}
      />
      {condition ? (
        <Typography variant="body2">
          <TransText i18nKey="refundable" />
        </Typography>
      ) : (
        <Typography variant="body2">
          <TransText i18nKey="nonRefundable" />
        </Typography>
      )}
    </div>
  );
};

export default Refundability;
