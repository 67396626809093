import { Icons } from '@/components/icons';
import type { FC } from 'react';

interface SuccessFailureIconProps {
  condition: boolean;
  isOfferEnabled: boolean;
}

const SuccessFailureIcon: FC<SuccessFailureIconProps> = ({
  condition,
  isOfferEnabled,
}) => {
  return condition ? (
    <Icons.checkCircle
      width={12}
      height={12}
      color={isOfferEnabled ? 'green' : 'gray'}
    />
  ) : (
    <Icons.errorCircle
      width={12}
      height={12}
      color={isOfferEnabled ? 'red' : 'gray'}
    />
  );
};

export default SuccessFailureIcon;
