import { useSelector } from '@/store/utils';
import { purchaseBookingSelector } from '@/features/purchase/purchaseSelectors';
import { manageBookingBookingSelector } from '@/features/manage-booking/manageBookingSelectors';
import type { Flow } from '@/types/booking';

export const useBooking = (flow: Flow) => {
  const purchaseBooking = useSelector(purchaseBookingSelector);
  const manageBookingBooking = useSelector(manageBookingBookingSelector);

  return flow === 'purchase' ? purchaseBooking : manageBookingBooking;
};
