import { FindBooking } from '@/components/manage-booking/FindBooking';
import { useSelector } from '@/store/utils';
import BookingDetails from '@/components/manage-booking/BookingDetails';
import { manageBookingBookingLoadingSelector } from '@/features/loading/loadingSelectors';
import { Loadable } from '@/components/Loadable';
import { manageBookingBookingSelector } from '@/features/manage-booking/manageBookingSelectors';

export const ManageBooking = () => {
  const booking = useSelector(manageBookingBookingSelector);
  const loading = useSelector(manageBookingBookingLoadingSelector);

  return (
    <Loadable loading={loading}>
      <div className="mx-auto mb-28 mt-16 w-full p-5 tablet:mb-16 tablet:max-w-mobile laptop:max-w-tablet desktop:max-w-laptop">
        {booking ? <BookingDetails /> : <FindBooking />}
      </div>
    </Loadable>
  );
};
