import type { InternalAxiosRequestConfig } from 'axios';

/**
 * Takes the access_token from local storage and injects it into requests
 * as the Authorization header
 */
export default function authorizationMiddleware(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  const accessToken = getAuthStorage()?.access_token;

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
}

function getAuthStorage() {
  const storageKey = ['oidc.user', import.meta.env?.VITE_APP_AUTH_URL].join(
    ':'
  );

  return JSON.parse(sessionStorage.getItem(storageKey) ?? '{}');
}
