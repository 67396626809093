import { Icons } from '@/components/icons/index';
import { cn } from '@/utils/cn';

export const TransportationTypeIcon = ({
  ptModeIcon,
  isRefundable = true,
}: {
  ptModeIcon: 'train' | 'bus';
  isRefundable?: boolean;
}) => {
  const IconComponent = Icons[ptModeIcon];

  return (
    <IconComponent
      height={16}
      width={16}
      className={cn('text-primary', { 'text-neutral': !isRefundable })}
    />
  );
};
