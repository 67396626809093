import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/primitives/Dialog';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { useSelector } from '@/store/utils';
import { addAncillaryLoadingSelector } from '@/features/loading/loadingSelectors';
import { Loadable } from '@/components/Loadable';
import { TransText } from '@/i18n/trans/text';
import { Button } from '@/components/primitives/Button';

interface RegularAncillaryModalProps {
  children: ReactNode;
  isOverlayOpened: boolean;
  setIsOverlayOpened: Dispatch<SetStateAction<boolean>>;
}

const RegularAncillaryModal: FC<RegularAncillaryModalProps> = ({
  children,
  isOverlayOpened,
  setIsOverlayOpened,
}) => {
  const loading = useSelector(addAncillaryLoadingSelector);

  return (
    <Dialog open={isOverlayOpened} onOpenChange={setIsOverlayOpened}>
      <DialogTrigger asChild>
        <Button
          variant="tertiary"
          size="medium"
          data-testid="add-new-ancillary-button-regular"
        >
          <Icons.addCircle height={16} width={16} />
          <TransText i18nKey="addNewAncillary" />
        </Button>
      </DialogTrigger>
      <DialogContent className="w-auto max-w-none rounded-2xl bg-white text-dark">
        <Loadable loading={loading} overlay>
          <DialogTitle />
          <DialogDescription hidden>
            <TransText i18nKey="chooseAncillaryAndConfirmChoice" />
          </DialogDescription>
          <DialogHeader className="px-6 py-4">
            <Typography variant="subtitle">
              <TransText i18nKey="addNewAncillary" />
            </Typography>
          </DialogHeader>
          {children}
        </Loadable>
      </DialogContent>
    </Dialog>
  );
};

export default RegularAncillaryModal;
