import { useContext } from 'react';
import { SearchWidgetContext } from '@/context/SearchWidgetContextProvider';

export const useSearchWidgetContext = () => {
  const context = useContext(SearchWidgetContext);
  if (context === undefined) {
    throw new Error(
      'useSearchOverlay must be used within a SearchOverlayProvider'
    );
  }
  return context;
};
