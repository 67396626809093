import { useMemo } from 'react';
import type { BookingDTO } from '@/types/dto';

export const useBookingTrips = (booking: BookingDTO) => {
  const { outboundTrips, inboundTrips } = useMemo(() => {
    const allTrips = booking?.bookedTrips || [];
    const outbound = allTrips.filter((trip) => trip.isOutbound);
    const inbound = allTrips.filter((trip) => !trip.isOutbound);
    return {
      outboundTrips: outbound,
      inboundTrips: inbound,
    };
  }, [booking]);

  return {
    outboundTrips,
    inboundTrips,
  };
};
