import type { AncillaryValues } from '@/utils/zod/schema';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormField, FormItem } from '@/components/primitives/Form';
import ToggleableRow from '@/components/purchase/checkout/ToggleableRow';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import { format } from 'date-fns';
import { Input } from '@/components/primitives/Input';
import { useBookingTripLegs } from '@/hooks/useBookingTripLegs';
import { TransText } from '@/i18n/trans/text';
import { useSelector } from '@/store/utils';
import { purchaseBookingSelector } from '@/features/purchase/purchaseSelectors';

interface LegOptionsProps {}

const LegOptions: FC<LegOptionsProps> = () => {
  const booking = useSelector(purchaseBookingSelector)!;
  const { outboundLegs, inboundLegs } = useBookingTripLegs(booking);
  const { watch, setValue, resetField } = useFormContext<AncillaryValues>();
  const activeLegId = watch('legId');

  return [...outboundLegs, ...inboundLegs].map((leg) => {
    const isActive = leg.id === activeLegId;

    return (
      <FormField
        name="legId"
        key={leg.id}
        render={({ field }) => (
          <FormItem>
            <FormControl hideLabel label={<TransText i18nKey="selectLeg" />}>
              <ToggleableRow
                isActive={isActive}
                onClick={() => {
                  setValue(field.name, leg.id!);
                  resetField('ancillaryOffers');
                }}
              >
                <Input
                  type="radio"
                  value={leg.id!}
                  id={leg.id!}
                  className="h-5 w-5 accent-primary"
                  checked={isActive}
                  readOnly
                  aria-label="Select leg"
                />
                <div className="flex w-full flex-col">
                  <div className="flex items-center gap-1 text-primary">
                    <Typography variant="body1-bold">
                      {leg.originStop?.name}
                    </Typography>
                    <Icons.vectorRight />
                    <Typography variant="body1-bold">
                      {leg.destinationStop?.name}
                    </Typography>
                  </div>
                  <div>
                    {leg.departureTime && (
                      <Typography
                        variant="body2"
                        className="flex flex-row items-center gap-1 text-neutral-light"
                      >
                        {format(leg.departureTime, 'EEE, dd MMM yyyy')}
                        <Icons.dot />
                        {format(leg.departureTime, 'HH:mm')}
                      </Typography>
                    )}
                  </div>
                </div>
              </ToggleableRow>
            </FormControl>
          </FormItem>
        )}
      />
    );
  });
};

export default LegOptions;
