import type { FC } from 'react';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAvailableAncillaries } from '@/hooks/useAvailableAncillaries';
import type { AncillaryValues } from '@/utils/zod/schema';
import { Typography } from '@/components/primitives/Typography';
import { FormControl, FormItem } from '@/components/primitives/Form';
import ToggleableRow from '@/components/purchase/checkout/ToggleableRow';
import { Input } from '@/components/primitives/Input';
import { Icons } from '@/components/icons';
import { TransText } from '@/i18n/trans/text';
import type { AdditionalOfferItem } from '@/types/offer';

interface AncillaryOptionsLegProps {}

const AncillaryOptions: FC<AncillaryOptionsLegProps> = () => {
  const { watch, setValue } = useFormContext<AncillaryValues>();
  const selectedLegId = watch('legId');
  const availableAncillaries = useAvailableAncillaries(selectedLegId);
  const selectedAncillaryOffers = watch('ancillaryOffers');

  const handleAncillaryToggle = useCallback(
    (
      add: boolean,
      { id, additionalOfferId, bookedOfferId, price }: AdditionalOfferItem
    ) => {
      if (add) {
        setValue('ancillaryOffers', [
          ...selectedAncillaryOffers,
          {
            id: id!,
            additionalOfferId,
            bookedOfferId,
            amount: price?.amount || 0,
          },
        ]);
      } else {
        const updatedAncillaryOffersList = selectedAncillaryOffers.filter(
          (selectedAncillary) => selectedAncillary.id !== id
        );
        setValue('ancillaryOffers', updatedAncillaryOffersList);
      }
    },
    [selectedAncillaryOffers, setValue]
  );

  return (
    <>
      {availableAncillaries.length ? (
        availableAncillaries.map((ancillary) => {
          const isActive = selectedAncillaryOffers.some(
            (selectedAncillary) => selectedAncillary.id === ancillary.id
          );

          return (
            <FormItem key={ancillary.id}>
              <FormControl
                hideLabel
                label={<TransText i18nKey="selectAncillary" />}
              >
                <ToggleableRow
                  isActive={isActive}
                  onClick={() => handleAncillaryToggle(!isActive, ancillary)}
                >
                  <Input
                    type="radio"
                    checked={isActive}
                    className="h-5 w-5 accent-primary"
                    readOnly
                    aria-label="Select ancillary"
                  />
                  <div className="w-full">
                    <Typography variant="body1">
                      {ancillary.products?.[0]?.description}
                    </Typography>
                  </div>
                  <Icons.bicycle />
                </ToggleableRow>
              </FormControl>
            </FormItem>
          );
        })
      ) : (
        <Typography variant="body2">
          <TransText i18nKey="legNotSelected" />
        </Typography>
      )}
    </>
  );
};

export default AncillaryOptions;
