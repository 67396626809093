import { createReducer } from '@reduxjs/toolkit';
import { AsyncActionStatus, isAsyncAction } from '@/store/utils';

export interface LoadingState {
  [key: string]: boolean;
}

const initialState: LoadingState = {};

export const loadingReducer = createReducer(initialState, (builder) => {
  builder.addDefaultCase((state, action) => {
    const { requestStatus } = action.meta ?? {};
    if (isAsyncAction(requestStatus)) {
      const regExp = new RegExp(
        `/(${Object.values(AsyncActionStatus).join('|')})`
      );
      const { type } = action;
      const typePrefix = type.replace(regExp, '');
      state[typePrefix] = requestStatus === AsyncActionStatus.PENDING;
    }
  });
});
