import { type FC, useState, useCallback } from 'react';
import { Icons } from '@/components/icons';
import { TransText } from '@/i18n/trans/text';
import { Typography } from '@/components/primitives/Typography';
import Divider from '@/components/primitives/Divider';
import { useDispatch, useSelector } from '@/store/utils';
import TripsByDirection from '@/components/manage-booking/TripsByDirection';
import { Button } from '@/components/primitives/Button';
import useBookingSummaryData from '@/hooks/useBookingSummaryData';
import RefundModal from '@/components/manage-booking/refund/RefundModal';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import RegularBookingSummary from '@/components/booking-summary/RegularBookingSummary';
import MobileBookingSummary from '@/components/booking-summary/MobileBookingSummary';
import { useBookingTrips } from '@/hooks/useBookingTrips';
import { useBookingRefundEligibleTrips } from '@/hooks/useBookingRefundEligibleTrips';
import { manageBookingBookingSelector } from '@/features/manage-booking/manageBookingSelectors';
import { Flow } from '@/types/booking';
import { clearManageBookingBooking } from '@/features/manage-booking/manageBookingActions';
import Footer from '@/components/Footer';

interface BookingDetailsProps {}

const BookingDetails: FC<BookingDetailsProps> = () => {
  const dispatch = useDispatch();
  const booking = useSelector(manageBookingBookingSelector)!;
  const summaryData = useBookingSummaryData(booking);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const { outboundTrips, inboundTrips } = useBookingTrips(booking);
  const { refundEligibleOutboundTrips, refundEligibleInboundTrips } =
    useBookingRefundEligibleTrips(booking);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const handleGoBack = useCallback(() => {
    dispatch(clearManageBookingBooking());
  }, [dispatch]);

  return (
    <div className="flex flex-col">
      <div id="go-back-section" className="mb-4 flex items-center gap-2">
        <Icons.arrowLeft onClick={handleGoBack} />
        <div className="flex gap-1">
          <Typography variant="subtitle" asChild>
            <h1>
              <TransText i18nKey="booking" />:
            </h1>
          </Typography>
          <Typography variant="body1">{booking?.code}</Typography>
        </div>
      </div>
      <div className="grid grid-cols-1 laptop:grid-cols-[1fr,auto] laptop:gap-10">
        <div className="flex flex-col">
          <TripsByDirection
            trips={outboundTrips}
            header={
              <TransText
                i18nKey="outboundTrips"
                values={{ count: outboundTrips.length }}
              />
            }
          />
          {!!inboundTrips.length && (
            <>
              <Divider className="my-3" />
              <TripsByDirection
                trips={inboundTrips}
                header={
                  <TransText
                    i18nKey="returnTrip"
                    values={{ count: inboundTrips.length }}
                  />
                }
              />
            </>
          )}
        </div>
        {isLaptopOrBigger && (
          <RegularBookingSummary {...summaryData} flow={Flow.manageBooking} />
        )}
      </div>
      <Footer
        content={
          !isLaptopOrBigger && (
            <MobileBookingSummary {...summaryData} flow={Flow.manageBooking} />
          )
        }
        actionButtons={
          <Button
            fullWidth
            size="regular"
            data-testid="refund-button"
            className="rounded-lg laptop:h-11 laptop:w-auto"
            onClick={() => setIsRefundModalOpen(true)}
            disabled={
              !refundEligibleOutboundTrips.length &&
              !refundEligibleInboundTrips.length
            }
          >
            <Icons.ticketReturn />
            <Typography variant="button">
              <TransText i18nKey="refund" />
            </Typography>
          </Button>
        }
      />
      <RefundModal
        isOpen={isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
      />
    </div>
  );
};

export default BookingDetails;
