import React, { type FC } from 'react';
import { Icons } from '@/components/icons';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/primitives/Sheet';
import type { NavigationItem } from '@/types/navigation';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/utils/cn';

type MobileNavMenuProps = {
  menuItems: Array<NavigationItem>;
};

const MobileNavMenu: FC<MobileNavMenuProps> = ({ menuItems }) => {
  const location = useLocation();

  return (
    <div
      className="flex w-full items-center justify-between gap-2 px-5 text-white"
      data-testid="navbar-mobile"
    >
      <Link to="/" aria-label="Hero">
        <Icons.turnitLogo />
      </Link>
      <Sheet>
        <SheetTrigger
          className="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-secondary focus-visible:ring-offset-2"
          data-testid="mobile-nav-open-button"
          aria-label="Open navigation menu"
        >
          <Icons.burgerMenu width={38} height={38} />
        </SheetTrigger>
        <SheetContent className="flex w-[250px] flex-col p-0" showClose>
          <SheetTitle className="sr-only">Mobile navigation menu</SheetTitle>
          <SheetDescription className="sr-only">
            Mobile navigation
          </SheetDescription>
          <SheetHeader className="border-b border-neutral-pale p-4 pt-4">
            <Icons.turnitLogo />
          </SheetHeader>
          <nav>
            {menuItems.map((item) => (
              <SheetClose asChild key={item.path}>
                <Link
                  to={item.path}
                  className={cn(
                    'mx-4 my-3 block text-sm transition duration-300 hover:text-secondary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-secondary focus-visible:ring-offset-2',
                    { 'text-secondary': item.path === location.pathname }
                  )}
                >
                  {item.name}
                </Link>
              </SheetClose>
            ))}
          </nav>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default MobileNavMenu;
