import { z } from 'zod';
import { paymentMethods } from '@/utils/consts';
import type { TFunction } from 'i18next';
import validationMessage from '@/utils/zod/validationMessage';

export const createPassengerSchema = (t: TFunction) => {
  const { mustBeFilled, invalidEmailFormat } = validationMessage(t);

  return z.object({
    id: z.string(),
    externalReference: z.string(),
    firstName: z.string().min(1, mustBeFilled),
    lastName: z.string().min(1, mustBeFilled),
    email: z.string().min(1, mustBeFilled).email(invalidEmailFormat),
    phone: z.object({
      number: z.string().min(1, mustBeFilled),
    }),
    age: z.number().nullable(),
  });
};
export type PassengerValues = z.infer<ReturnType<typeof createPassengerSchema>>;

export const createPurchaserSchema = (t: TFunction) => {
  const { mustBeFilled, invalidEmailFormat } = validationMessage(t);

  return z.object({
    externalReference: z.string().optional(),
    firstName: z.string().min(1, mustBeFilled),
    lastName: z.string().min(1, mustBeFilled),
    email: z.string().min(1, mustBeFilled).email(invalidEmailFormat),
    phone: z.object({
      number: z.string().min(1, mustBeFilled),
    }),
  });
};
export type PurchaserValues = z.infer<ReturnType<typeof createPurchaserSchema>>;

export const createPaymentMethodSchema = (t: TFunction) => {
  const { invalidPaymentMethod } = validationMessage(t);

  return z.object({
    id: z
      .string()
      .refine(
        (val) => paymentMethods.find(({ id }) => val === id),
        invalidPaymentMethod
      ),
  });
};
export type PaymentMethodValues = z.infer<
  ReturnType<typeof createPaymentMethodSchema>
>;

export const createConsentsSchema = (t: TFunction) => {
  const { mustAgreeBeforeContinuing } = validationMessage(t);

  return z.object({
    consents: z
      .array(
        z.object({
          isMandatory: z.boolean(),
          description: z.string().optional(),
          checked: z.boolean(),
          id: z.union([z.number(), z.string()]),
        })
      )
      .superRefine((consents, ctx) => {
        consents.forEach((consent, index) => {
          if (consent.isMandatory && !consent.checked) {
            ctx.addIssue({
              path: [index, 'checked'],
              code: z.ZodIssueCode.custom,
              ...mustAgreeBeforeContinuing,
            });
          }
        });
      }),
  });
};
export type ConsentsValues = z.infer<ReturnType<typeof createConsentsSchema>>;

export const createAncillarySchema = (t: TFunction) => {
  const { minimumOne, mustChooseLeg } = validationMessage(t);

  return z.object({
    passengersExternalReferences: z.array(z.string()).min(1, minimumOne),
    legId: z.string().min(1, mustChooseLeg),
    ancillaryOffers: z
      .array(
        z.object({
          id: z.string(),
          bookedOfferId: z.string(),
          additionalOfferId: z.string(),
          amount: z.number(),
        })
      )
      .min(1, minimumOne),
  });
};
export type AncillaryValues = z.infer<ReturnType<typeof createAncillarySchema>>;

export const createFindBookingSchema = (t: TFunction) => {
  const { mustBeFilled, invalidEmailFormat } = validationMessage(t);

  return z.object({
    email: z.string().min(1, mustBeFilled).email(invalidEmailFormat),
    bookingNumber: z.string().min(1, mustBeFilled),
  });
};
export type FindBookingValues = z.infer<
  ReturnType<typeof createFindBookingSchema>
>;
