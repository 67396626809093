import { format, formatDate, intervalToDuration, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import type { DurationDTO } from '@/types/dto';

export const formatFullDate = (date: Date) => {
  return date && format(date, "yyyy-MM-dd'T'HH:mm:ss");
};

export const formatDay = (date?: string) => {
  return date && formatDate(date, 'd MMM');
};

export const formatTime = (date?: string) => {
  return date && formatDate(date, 'HH:mm');
};

export const formatDateLLL = (date?: Date) => {
  return date && format(date, 'd LLL');
};

export const getDurationBetweenTimestamps = (
  first?: string,
  second?: string
) => {
  if (!first || !second) {
    return null;
  }

  const timeDiff = new Date(second).getTime() - new Date(first).getTime();

  if (timeDiff > 0) {
    const duration = intervalToDuration({ start: 0, end: timeDiff });
    return `${duration.hours ? duration.hours + 'h:' : ''}${duration.minutes}m`;
  }

  return null;
};

export const formatDuration = (duration: DurationDTO | undefined) =>
  duration && duration.substring(2).toLowerCase().replace('h', 'h ');

export const formatDateWithDayOfWeek = (dateString?: string) => {
  return dateString && format(parseISO(dateString), 'EEE, d MMM yyyy');
};

export const isTimestampInPast = (
  departureTimeIsoString: string,
  timeZone: string
) => {
  const now = new Date();
  const nowInTimeZone = toZonedTime(now, timeZone);

  return new Date(departureTimeIsoString) < nowInTimeZone;
};
