import type { FC, ReactNode } from 'react';
import { cn } from '@/utils/cn';

interface ToggleableRowProps {
  isActive: boolean;
  children: ReactNode;
  onClick?: () => void;
}
const ToggleableRow: FC<ToggleableRowProps> = ({
  isActive,
  children,
  onClick,
}) => {
  return (
    <div
      className={cn(
        'flex items-center gap-2 rounded-lg border border-neutral-light p-3',
        {
          ['border-primary bg-primary/20']: isActive,
          ['hover:bg-primary/10']: !isActive,
        }
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default ToggleableRow;
