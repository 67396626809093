import type { FC } from 'react';
import type { BookedTripDTO } from '@/types/dto';
import HighlightedRow from '@/components/booking-summary/HighlightedRow';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { format, isSameDay } from 'date-fns';
import { formatDay, formatTime } from '@/utils/date-time';

interface TripDescriptionProps {
  trips: Array<BookedTripDTO>;
  direction: 'outbound' | 'inbound';
}

const TripDescription: FC<TripDescriptionProps> = ({ trips, direction }) => {
  return (
    <div>
      <HighlightedRow className="px-3">
        <div className="flex flex-row gap-2">
          {/* FIXME: transportReturn and transportFrom default icon sizes dont match */}
          {direction === 'outbound' ? (
            <Icons.transportFrom className="h-5 w-5 text-primary" />
          ) : (
            <Icons.transportReturn className="h-5 w-5 text-primary" />
          )}
          <Typography variant="body1-bold">
            <TransText
              i18nKey={
                direction === 'outbound' ? 'outboundTrip' : 'inboundTrip'
              }
            />
          </Typography>
        </div>
        <Typography variant="body2" className="text-neutral">
          {/* TODO: Move to date.ts file */}
          {format(trips[0].departureTime, 'EEE, dd MMM yyyy')}
        </Typography>
      </HighlightedRow>
      {trips.map(({ legs }) =>
        legs?.map((leg, key) => (
          <div className="flex flex-row gap-2 px-4 py-3" key={key}>
            <div className="flex w-fit flex-col">
              <div className="h-4 w-4">
                <Icons.bus className="h-4 w-4 text-primary" />
              </div>
              <div className="mx-auto h-full w-0.5 bg-primary" />
              <div className="h-4 w-4">
                <Icons.destination className="h-4 w-4 text-primary" />
              </div>
            </div>
            <div className="flex w-full flex-col gap-2">
              <div className="flex flex-row justify-between">
                <Typography variant="body2-bold">
                  {leg.originStop?.name}
                </Typography>
                <Typography variant="body2">
                  {formatTime(leg.departureTime)}
                </Typography>
              </div>
              <div className="flex flex-row justify-between">
                <Typography variant="body2-bold">
                  {leg.destinationStop?.name}
                </Typography>
                <div className="flex gap-2">
                  {isSameDay(leg.departureTime!, leg.arrivalTime!) ? null : (
                    <Typography variant="body2" className="text-neutral-light">
                      {formatDay(leg.arrivalTime)}
                    </Typography>
                  )}
                  <Typography variant="body2">
                    {formatTime(leg.arrivalTime)}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default TripDescription;
