import { createAsyncThunk } from '@/store/utils';
import getApiInstance from '@/api';
import { createAction } from '@reduxjs/toolkit';
import type { STEP } from '@/utils/consts';
import type {
  AdditionalOfferPartDTO,
  BookingDTO,
  CreateBookingResponseDTO,
  ErrorDTO,
  JourneyDTO,
  NonTripOfferDTO,
  NonTripOfferSearchRequestBodyDTO,
  OfferSearchRequestBodyDTO,
  RemoveAncillaryParametersDTO,
  SearchNonTripOffersDTO,
  SearchOffersDTO,
} from '@/types/dto';
import type { SearchFormValues } from '@turnit-ride-ui/webshop-search-widget/widget';
import type {
  AdditionalOfferItem,
  AdditionalOffersCollection,
  OfferMapByLegId,
  OfferMapItem,
} from '@/types/offer';
import type {
  AncillaryValues,
  PassengerValues,
  PurchaserValues,
} from '@/utils/zod/schema';
import type { AxiosResponse } from 'openapi-client-axios';
import { createBooking } from '@/features/purchase/purchaseService';

export const getOutboundJourneys = createAsyncThunk<
  SearchOffersDTO,
  Omit<
    OfferSearchRequestBodyDTO,
    'currency' | 'promotionCodes' | 'corporateCodes'
  >
>(
  'purchase/getOutboundJourneys',
  async (payload, { dispatch }) => await dispatch(getJourneys(payload)).unwrap()
);

export const getInboundJourneys = createAsyncThunk<
  SearchOffersDTO,
  Omit<
    OfferSearchRequestBodyDTO,
    'currency' | 'promotionCodes' | 'corporateCodes'
  >
>(
  'purchase/getInboundJourneys',
  async (payload, { dispatch }) => await dispatch(getJourneys(payload)).unwrap()
);

export const getJourneys = createAsyncThunk<
  SearchOffersDTO,
  Omit<
    OfferSearchRequestBodyDTO,
    'currency' | 'promotionCodes' | 'corporateCodes'
  >
>('purchase/getJourneys', async (payload, { getState }) => {
  const api = (await getApiInstance()).agentApi;
  const {
    configuration: {
      currency: { name: currency },
    },
  } = getState();

  return (
    await api.Offers_FindOffers(null, {
      currency: currency!,
      promotionCodes: [],
      corporateCodes: [],
      ...payload,
    })
  ).data;
});

export const getNonTripOffers = createAsyncThunk<
  SearchNonTripOffersDTO,
  Omit<
    NonTripOfferSearchRequestBodyDTO,
    'currency' | 'promotionCodes' | 'corporateCodes'
  >
>('purchase/getNonTripOffers', async (payload, { getState }) => {
  const api = (await getApiInstance()).agentApi;
  const {
    configuration: {
      currency: { name: currency },
    },
  } = getState();

  return (
    await api.Offers_FindNonTripOffers(null, {
      currency: currency!,
      promotionCodes: [],
      corporateCodes: [],
      ...payload,
    })
  ).data;
});

export const updateSelectedNonTripOffer = createAction<NonTripOfferDTO>(
  'purchase/updateSelectedNonTripOffer'
);

export const setActiveStep = createAction<STEP>('purchase/setActiveStep');

export const setSelectedOutboundJourney = createAction<JourneyDTO>(
  'purchase/setSelectedOutboundJourney'
);

export const setSelectedInboundJourney = createAction<JourneyDTO>(
  'purchase/setSelectedInboundJourney'
);

export const setSelectedOutboundOfferMapByLegId = createAction<OfferMapByLegId>(
  'purchase/setSelectedOutboundOfferMapByLegId'
);

export const updateSelectedOutboundOfferMapLegId = createAction<OfferMapItem>(
  'purchase/updateSelectedOutboundOfferMapLegId'
);

export const setSelectedInboundOfferMap = createAction<OfferMapByLegId>(
  'purchase/setSelectedInboundOfferMap'
);

export const updateSelectedInboundOfferMapLegId = createAction<OfferMapItem>(
  'purchase/updateSelectedInboundOfferMapLegId'
);

export const resetPurchase = createAction<{ startStep: STEP } | undefined>(
  'purchase/resetPurchase'
);

export const getAdditionalOffersSearch = createAsyncThunk<
  {
    outboundAdditionalOffers: Array<AdditionalOffersCollection>;
    inboundAdditionalOffers: Array<AdditionalOffersCollection>;
  },
  string
>(
  'purchase/booking/getAdditionalOffersSearch',
  async (bookingId, { getState }) => {
    const api = (await getApiInstance()).agentApi;

    const outboundOffersMap =
      getState().purchase.outbound.selectedOfferMapByLegId;
    const outboundOfferIds = Object.values(outboundOffersMap).map(
      ({ id }) => id!
    );

    const inboundOffersMap =
      getState().purchase.inbound.selectedOfferMapByLegId;
    const inboundOfferIds = Object.values(inboundOffersMap).map(
      ({ id }) => id!
    );

    const responseItems = (
      await api.BookedOffers_GetAdditionalOffersSearch(
        {
          bookingId,
        },
        {
          bookedOfferIds: [...outboundOfferIds, ...inboundOfferIds],
        }
      )
    ).data.items;

    const mergeOfferParts = ({
      existingParts,
      newParts,
      bookedOfferId,
      additionalOfferId,
    }: {
      existingParts?: Array<AdditionalOfferItem>;
      newParts?: Array<AdditionalOfferPartDTO>;
      bookedOfferId: string;
      additionalOfferId?: string;
    }): Array<AdditionalOfferItem> => [
      ...(existingParts || []),
      ...(newParts || []).map((offer) => ({
        ...offer,
        bookedOfferId,
        additionalOfferId: additionalOfferId!,
      })),
    ];

    const additionalOfferCollectionByOfferId =
      responseItems?.reduce<Record<string, AdditionalOffersCollection>>(
        (
          acc,
          {
            bookedOfferId,
            additionalOfferId,
            ancillaryOfferParts,
            reservationOfferParts,
            admissionOfferParts,
          }
        ) => {
          return !bookedOfferId
            ? acc
            : {
                ...acc,
                [bookedOfferId]: {
                  ancillaryOfferParts: mergeOfferParts({
                    existingParts: acc[bookedOfferId]?.ancillaryOfferParts,
                    newParts: ancillaryOfferParts,
                    bookedOfferId,
                    additionalOfferId,
                  }),
                  reservationOfferParts: mergeOfferParts({
                    existingParts: acc[bookedOfferId]?.reservationOfferParts,
                    newParts: reservationOfferParts,
                    bookedOfferId,
                    additionalOfferId,
                  }),
                  admissionOfferParts: mergeOfferParts({
                    existingParts: acc[bookedOfferId]?.admissionOfferParts,
                    newParts: admissionOfferParts,
                    bookedOfferId,
                    additionalOfferId,
                  }),
                },
              };
        },
        {}
      ) || {};

    return {
      outboundAdditionalOffers: outboundOfferIds.map(
        (id) => additionalOfferCollectionByOfferId[id]
      ),
      inboundAdditionalOffers: inboundOfferIds.map(
        (id) => additionalOfferCollectionByOfferId[id]
      ),
    };
  }
);

export const setSearchFormValues = createAction<SearchFormValues>(
  'purchase/setSearchFormValues'
);

export const payWithExternalPayment = createAsyncThunk<
  unknown,
  {
    bookingId: string | undefined;
    paidAmount: { amount: number; currency: string };
    transactionId: string;
    typeId: string;
  }
>('purchase/payWithExternalPayment', async ({ bookingId, ...data }) => {
  const api = (await getApiInstance()).agentApi;

  if (!bookingId) {
    return;
  }

  return await api.Payments_PayWithExternalPayment({ bookingId }, data);
});

export const updatePassengers = createAsyncThunk<
  object,
  Array<PassengerValues>
>('purchase/booking/updatePassengers', async (passengers, { getState }) => {
  const api = (await getApiInstance()).agentApi;
  const bookingId = getState().purchase.booking!.id!;

  const responses = [];

  for (const { id: passengerId, ...rest } of passengers) {
    const response = await api.Bookings_UpdatePassenger(
      { bookingId, passengerId },
      rest
    );
    responses.push(response);
  }

  return responses;
});

export const updatePurchaser = createAsyncThunk<object, PurchaserValues>(
  'purchase/booking/updatePurchaser',
  async (purchaser, { getState }) => {
    const api = (await getApiInstance()).agentApi;
    const bookingId = getState().purchase.booking!.id!;

    return await api.Bookings_UpdatePurchaser({ bookingId }, purchaser);
  }
);

export const addAncillary = createAsyncThunk<
  Array<AxiosResponse<ErrorDTO>> | void,
  AncillaryValues
>('purchase/booking/addAncillary', async (data, { getState }) => {
  const api = (await getApiInstance()).agentApi;
  const bookingId = getState().purchase.booking!.id!;

  // TODO: Refactor to single request once this task is completed: https://youtrack.tsolutions.co/issue/BR-49676
  const failedResults = [];

  for (const {
    id,
    bookedOfferId,
    additionalOfferId: offerId,
  } of data.ancillaryOffers || []) {
    const response = await api.BookedOffers_AddAncillary(
      { bookingId, bookedOfferId },
      {
        ancillaryId: id!,
        offerId,
        passengerRefs: [
          data.passengersExternalReferences![0],
          ...data.passengersExternalReferences!.slice(1),
        ],
      }
    );

    if (response.status !== 204) {
      failedResults.push(response);
    }
  }

  if (failedResults.length) {
    return failedResults;
  }
});

export const removeAncillary = createAsyncThunk<
  object,
  Omit<RemoveAncillaryParametersDTO, 'bookingId'>
>('purchase/booking/removeAncillary', async (pathParams, { getState }) => {
  const api = (await getApiInstance()).agentApi;
  const bookingId = getState().purchase.booking!.id!;

  return (
    await api.BookedOffers_DeleteAncillary({
      bookingId,
      ...pathParams,
    })
  ).data;
});

export const createTicketBooking = createAsyncThunk<
  CreateBookingResponseDTO,
  SearchFormValues['passengers']
>('purchase/booking/createTicketBooking', async (passengers, { getState }) => {
  const { outbound, inbound } = getState().purchase;
  const passengerExternalReferences = passengers.map(
    ({ externalReference }) => externalReference!
  );

  const offers = [
    ...Object.values(outbound.selectedOfferMapByLegId),
    ...Object.values(inbound.selectedOfferMapByLegId),
  ].map((offer) => ({ id: offer.id!, passengerExternalReferences }));

  return createBooking(offers, passengers);
});

export const createNonTripOfferBooking = createAsyncThunk<
  CreateBookingResponseDTO,
  SearchFormValues['passengers']
>(
  'purchase/booking/createNonTripOfferBooking',
  async (passengers, { getState }) => {
    const { nonTripOffers } = getState().purchase;
    const passengerExternalReferences = passengers.map(
      ({ externalReference }) => externalReference!
    );
    const offers = nonTripOffers.selectedNonTripOffer?.id
      ? [
          {
            id: nonTripOffers.selectedNonTripOffer?.id,
            passengerExternalReferences,
          },
        ]
      : [];

    return createBooking(offers, passengers);
  }
);

export const getPurchaseBookingById = createAsyncThunk<BookingDTO, string>(
  'purchase/booking/getPurchaseBookingById',
  async (id) => {
    const api = (await getApiInstance()).agentApi;

    return (await api.Bookings_GetBooking(id)).data;
  }
);

export const clearPurchaseBooking = createAction(
  'purchase/booking/clearPurchaseBooking'
);
