import type { FC } from 'react';
import type { OfferDTO } from '@/types/dto';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/primitives/Dialog';
import Divider from '@/components/primitives/Divider';
import { TransText } from '@/i18n/trans/text';
import Refundability from '@/components/purchase/card/Refundability';
import Changeability from '@/components/purchase/card/Changeability';

interface OfferDetailsDialogProps {
  offer: OfferDTO;
  journeyDetails: {
    originStopName?: string;
    destinationStopName?: string;
  };
  isOpen: boolean;
  onClose: () => void;
}

const OfferDetailsDialog: FC<OfferDetailsDialogProps> = ({
  offer,
  journeyDetails,
  isOpen,
  onClose,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="bg-white text-dark">
        <DialogHeader className="w-full p-6 pr-[50px] text-start">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-2">
              <DialogTitle>
                <TransText i18nKey="fareDetails" />
              </DialogTitle>
              <DialogDescription className="flex flex-row items-center gap-2">
                <Typography variant="body2">
                  {journeyDetails.originStopName}
                </Typography>
                <Icons.vectorRight width={16} height={16} />
                <Typography variant="body2">
                  {journeyDetails.destinationStopName}
                </Typography>
              </DialogDescription>
            </div>
            <Typography variant="subtitle" className="flex gap-1">
              <span>{offer.price?.amount}</span>
              <span>{offer.price?.currency}</span>
            </Typography>
          </div>
        </DialogHeader>
        <DialogBody className="px-6 pb-6">
          <Typography variant="subtitle">{offer.productSummary}</Typography>
          <Typography variant="body1">{offer.productDescription}</Typography>
          {/* TODO: Get carrier info + image from somewhere */}
          <div className="flex gap-1">
            <Typography variant="body2">LOGO TBD</Typography>
            <Typography variant="body2">Carrier name TBD</Typography>
          </div>
          <Typography variant="body1">{offer.summary}</Typography>
          <div className="flex flex-col gap-2">
            <Typography variant="subtitle">Flexibility</Typography>
            <div className="flex flex-row gap-2">
              <Refundability condition={offer.refundable === 'YES'} />
              <Changeability condition={offer.exchangeable === 'YES'} />
            </div>
            <Divider className="border-t-neutral-pale" />
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

export default OfferDetailsDialog;
