import type { FC } from 'react';
import React from 'react';
import { cn } from '@/utils/cn';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import { Checkbox } from '@/components/primitives/Checkbox';
import type { LegDTO } from '@/types/dto';
import { PlaceIcon } from '@/components/icons/PlaceIcon';

interface PassengerSelectionCardProps {
  passenger: {
    firstName: string;
    lastName: string;
    carriages?: string;
    seats?: string;
  };
  ptMode?: LegDTO['ptMode'];
  isSecondLevelItem?: boolean;
  className?: string;
  placeIcon?: 'seat' | 'compartment' | 'bed';
  isChecked: boolean;
  onToggle: (checked: boolean) => void;
}

export const PassengerSelectionCard: FC<PassengerSelectionCardProps> = ({
  passenger,
  isSecondLevelItem = false,
  placeIcon = 'seat',
  className,
  isChecked,
  onToggle,
}) => {
  return (
    <div
      className={cn(
        'cursor-pointer rounded-sm border border-neutral-light px-3 py-1.5 hover:border-primary-medium hover:bg-primary-pale',
        className,
        {
          'border-primary bg-primary-light': isChecked,
        }
      )}
      onClick={() => onToggle(!isChecked)}
      data-state={isChecked ? 'checked' : 'unchecked'}
    >
      <div className="flex items-center gap-3">
        <Checkbox checked={isChecked} onChange={() => onToggle(!isChecked)} />
        <div className="w-full">
          <div className="flex items-center justify-between gap-2">
            <Typography
              variant={isSecondLevelItem ? 'body2-bold' : 'body1-bold'}
            >
              {`${passenger.firstName} ${passenger.lastName}`}
            </Typography>
            <div className="flex items-center gap-1.5">
              {passenger.carriages && (
                <div className="flex items-center gap-1">
                  <Icons.carriage
                    height={12}
                    width={12}
                    className="text-neutral"
                  />
                  <Typography variant="body2-bold" className="text-neutral">
                    {passenger.carriages}
                  </Typography>
                </div>
              )}
              <div className="flex items-center gap-1">
                <PlaceIcon placeIcon={placeIcon} />
                <Typography variant="body2-bold" className="text-neutral">
                  {passenger.seats || '-'}
                </Typography>
              </div>
            </div>
          </div>
          {/*TODO: When we have card info implement this:*/}
          {/*<Typography*/}
          {/*  variant={isSecondLevelItem ? 'label' : 'body2'}*/}
          {/*  className="text-neutral"*/}
          {/*>*/}
          {/*  Passenger*/}
          {/*</Typography>*/}
        </div>
      </div>
    </div>
  );
};
