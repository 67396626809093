import type { LegDTO, PlaceAllocationDTO } from '@/types/dto';
import { TransportType } from '@/utils/consts';

export const getPlaceIconName = (
  placeAllocation: PlaceAllocationDTO
): 'seat' | 'compartment' | 'bed' => {
  if (!placeAllocation) return 'seat';

  const { accomodationType, accommodationSubType } = placeAllocation;

  if (accomodationType === 'SEAT') return 'seat';

  return accommodationSubType?.includes('COMPARTMENT') ? 'compartment' : 'bed';
};

export const getPtModeIconName = (ptMode: LegDTO['ptMode']) => {
  switch (ptMode?.code) {
    case TransportType.HIGH_SPEED_TRAIN:
    case TransportType.INTERCITY_REGIONAL:
    case TransportType.LOCAL_TRAIN:
    case TransportType.TRAIN:
      return 'train';
    case TransportType.BUS:
    case TransportType.LONG_DISTANCE:
    case TransportType.LOCAL_BUS:
      return 'bus';
    default: {
      return 'train';
    }
  }
};
