import { type BookingSummaryData } from '@/hooks/useBookingSummaryData';
import TripDescription from '@/components/booking-summary/TripDescription';
import NonTripOfferDescription from '@/components/booking-summary/NonTripOfferDescription';
import BookingItems from '@/components/booking-summary/BookingItems';
import type { FC } from 'react';

interface SummaryContentProps extends BookingSummaryData {}

const SummaryContent: FC<SummaryContentProps> = ({
  inboundTickets,
  inboundTrips,
  outboundTickets,
  outboundTrips,
  ancillaries,
  nonTripOffers,
  nonTripOfferFees,
}) => {
  return (
    <>
      {!!outboundTrips.length && (
        <TripDescription trips={outboundTrips} direction="outbound" />
      )}
      {!!inboundTrips.length && (
        <TripDescription trips={inboundTrips} direction="inbound" />
      )}
      {!!nonTripOffers.length && (
        <NonTripOfferDescription nonTripOffers={nonTripOffers} />
      )}
      <BookingItems
        outboundTickets={outboundTickets}
        inboundTickets={inboundTickets}
        ancillaries={ancillaries}
        nonTripOfferFees={nonTripOfferFees}
        nonTripOffers={nonTripOffers}
      />
    </>
  );
};

export default SummaryContent;
