import { Typography } from '@/components/primitives/Typography';
import SuccessFailureIcon from '@/components/purchase/card/SuccessFailureIcon';
import { TransText } from '@/i18n/trans/text';
import type { FC } from 'react';

interface ChangeabilityProps {
  condition: boolean;
  isOfferEnabled?: boolean;
}

const Changeability: FC<ChangeabilityProps> = ({
  condition,
  isOfferEnabled = true,
}) => {
  return (
    <div className="flex flex-row items-center gap-1">
      <SuccessFailureIcon
        condition={condition}
        isOfferEnabled={isOfferEnabled}
      />
      {condition ? (
        <Typography variant="body2">
          <TransText i18nKey="changeable" />
        </Typography>
      ) : (
        <Typography variant="body2">
          <TransText i18nKey="unchangeable" />
        </Typography>
      )}
    </div>
  );
};

export default Changeability;
