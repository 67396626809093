import { createReducer } from '@reduxjs/toolkit';
import {
  initiateRefund,
  deleteRefundOffer,
  toggleLeg,
  toggleAdmission,
  resetSelection,
  getManageBookingBookingById,
  clearManageBookingBooking,
} from '@/features/manage-booking/manageBookingActions';
import type { AdmissionDTO, BookingDTO, RefundOffersDTO } from '@/types/dto';
import { getManageBookingBookingByNumberAndEmail } from '@/features/manage-booking/manageBookingService';

type ManageBookingState = {
  refund: {
    selectedAdmissions: { [legId: string]: AdmissionDTO[] };
    details: RefundOffersDTO | null;
  };
  booking: BookingDTO | null;
};

const initialState: ManageBookingState = {
  refund: {
    selectedAdmissions: {},
    details: null,
  },
  booking: null,
};

export const manageBookingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(toggleLeg, (state, action) => {
      const { legId, admissions } = action.payload;
      if (admissions.length > 0) {
        state.refund.selectedAdmissions[legId] = admissions;
      } else {
        state.refund.selectedAdmissions = Object.fromEntries(
          Object.entries(state.refund.selectedAdmissions).filter(
            ([key]) => key !== legId
          )
        );
      }
    })
    .addCase(toggleAdmission, (state, action) => {
      const { legId, admissions } = action.payload;
      if (admissions.length > 0) {
        state.refund.selectedAdmissions[legId] = admissions;
      } else {
        state.refund.selectedAdmissions = Object.fromEntries(
          Object.entries(state.refund.selectedAdmissions).filter(
            ([key]) => key !== legId
          )
        );
      }
    })
    .addCase(resetSelection, (state) => {
      state.refund.selectedAdmissions = {};
      state.refund.details = null;
    })
    .addCase(initiateRefund.fulfilled, (state, action) => {
      state.refund.details = action.payload;
    })
    .addCase(deleteRefundOffer.fulfilled, (state) => {
      state.refund.details = null;
    })
    .addCase(
      getManageBookingBookingByNumberAndEmail.fulfilled,
      (state, action) => {
        state.booking = action.payload;
      }
    )
    .addCase(getManageBookingBookingById.fulfilled, (state, action) => {
      state.booking = action.payload;
    })
    .addCase(clearManageBookingBooking, (state) => {
      Object.assign(state, {
        ...initialState,
      });
    });
});
