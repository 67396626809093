import * as React from 'react';

import { cn } from '@/utils/cn';
import { typographyVariants } from '@/components/primitives/Typography';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          typographyVariants({ variant: 'body1' }),
          'flex h-8 w-full rounded-md border border-neutral-medium bg-white px-3 py-2 text-dark file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral hover:border-primary-medium focus-visible:border-primary focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary disabled:cursor-not-allowed disabled:border-neutral-light disabled:bg-greyscale-100 disabled:placeholder:text-neutral-medium',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

export { Input };
