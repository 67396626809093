import ReactDOM from 'react-dom/client';
import App from '@/App';
import { initializeSentry } from '@/sentryConfig';
import { store } from '@/store';
import { Provider as StoreProvider } from 'react-redux';
import { assertEnvVars } from '@/utils/assertEnvVars';

import '@turnit-ride-ui/webshop-search-widget/style.css';
import '@/index.css';
import '@/i18n';
import 'react-toastify/dist/ReactToastify.css';

initializeSentry();
assertEnvVars();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>
);
