import { type FC, useCallback } from 'react';
import { useDispatch, useSelector } from '@/store/utils';
import Divider from '@/components/primitives/Divider';
import LegSection from '@/components/purchase/checkout/ancillaries/LegSection';
import AncillaryModal from '@/components/purchase/checkout/ancillaries/ancillary-modal/AncillaryModal';
import { Typography } from '@/components/primitives/Typography';
import type { BookedAncillaryDTO, LegDTO } from '@/types/dto';
import { useBookingTripLegs } from '@/hooks/useBookingTripLegs';
import type { UseFormReturn } from 'react-hook-form';
import type { PassengerValues } from '@/utils/zod/schema';
import { TransText } from '@/i18n/trans/text';
import { purchaseBookingSelector } from '@/features/purchase/purchaseSelectors';
import {
  getPurchaseBookingById,
  removeAncillary,
} from '@/features/purchase/purchaseActions';

interface AncillaryListProps {
  passengerListForm: UseFormReturn<{
    passengers: Array<PassengerValues>;
  }>;
}

const AncillaryList: FC<AncillaryListProps> = ({ passengerListForm }) => {
  const dispatch = useDispatch();
  const booking = useSelector(purchaseBookingSelector)!;
  const { outboundLegs, inboundLegs } = useBookingTripLegs(booking);

  const handleRemoveAncillary = useCallback(
    async (ancillary: BookedAncillaryDTO) => {
      await dispatch(
        removeAncillary({
          ancillaryId: ancillary.id!,
          bookedOfferId: ancillary.bookedOfferId!,
        })
      ).unwrap();
      await dispatch(getPurchaseBookingById(booking!.id!));
    },
    [booking, dispatch]
  );

  const renderLegWithChosenAncillaries = useCallback(
    (leg: LegDTO, index: number) => (
      <LegSection
        leg={leg}
        handleRemoveAncillary={handleRemoveAncillary}
        key={index}
        passengerListForm={passengerListForm}
      />
    ),
    [handleRemoveAncillary, passengerListForm]
  );

  return (
    <div className="flex min-w-mobile flex-col gap-3">
      <div className="flex flex-col text-dark tablet:flex-row tablet:gap-3">
        <div className="flex flex-col tablet:min-w-[45%]">
          <Typography variant="body1-bold" className="text-dark" asChild>
            <h3>
              <TransText i18nKey="outboundTrip" />
            </h3>
          </Typography>
          {outboundLegs.map(renderLegWithChosenAncillaries)}
        </div>
        {inboundLegs.length ? (
          <>
            <Divider className="h-auto border-gray-200 py-2 tablet:border-l" />
            <div className="flex flex-col tablet:min-w-[50%] tablet:pl-6">
              <Typography variant="body1-bold" className="text-dark" asChild>
                <h3>
                  <TransText i18nKey="returnTrip" />
                </h3>
              </Typography>
              {inboundLegs.map(renderLegWithChosenAncillaries)}
            </div>
          </>
        ) : null}
      </div>
      <AncillaryModal passengerListForm={passengerListForm} />
    </div>
  );
};

export default AncillaryList;
