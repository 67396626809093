import * as React from 'react';
import { cn } from '@/utils/cn';

export interface RadioProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ className, ...props }, ref) => {
    return (
      <input
        type="radio"
        className={cn(
          'relative h-5 w-5 shrink-0 grow-0 cursor-pointer appearance-none rounded-full border border-neutral bg-white',
          'before:absolute before:left-1/2 before:top-1/2 before:h-2.5 before:w-2.5 before:rounded-full before:bg-white before:content-[""] before:disabled:bg-neutral-light',
          'before:-translate-x-1/2 before:-translate-y-1/2 before:transform before:opacity-0',
          'checked:border-primary checked:bg-primary checked:before:opacity-100 checked:hover:bg-primary checked:disabled:border-neutral-light checked:disabled:bg-neutral',
          'hover:border-primary-dark hover:bg-primary-pale',
          'focus:outline-none focus-visible:ring-2 focus-visible:ring-secondary focus-visible:ring-offset-2',
          'transition-colors duration-200 ease-in-out',
          'disabled:cursor-not-allowed disabled:border-neutral-light disabled:bg-greyscale-100',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

Radio.displayName = 'Radio';

export { Radio };
