import type { TransKeys } from '@/i18n/trans/index';
import { createTrans } from '@/i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  ancillaryInfoMissing: (
    <Trans
      i18nKey="alert.ancillaryInfoMissing"
      defaults="Fill all necessary fields to add ancillaries."
    />
  ),
  bookingCancellationFailed: (
    <Trans
      i18nKey="alert.bookingCancellationFailed"
      defaults="Failed to cancel booking"
    />
  ),
  bookingIdMissing: (
    <Trans
      i18nKey="alert.bookingIdMissing"
      defaults="Received booking without id"
    />
  ),
  bookingsSearchFailed: (
    <Trans
      i18nKey="alert.bookingsSearchFailed"
      defaults="Did not find a booking with this email and number. Please check the inserted data."
    />
  ),
  consentsNotAgreedWith: (
    <Trans
      i18nKey="alert.consentsNotAgreedWith"
      defaults="You must accept the mandatory consents in order to continue."
    />
  ),
  mandatoryFieldsMustBeFilled: (
    <Trans
      i18nKey="alert.mandatoryFieldsMustBeFilled"
      defaults='All fields must be filled, if not marked as "optional".'
    />
  ),
  paymentFailed: (
    <Trans i18nKey="alert.paymentFailed" defaults="Payment failed" />
  ),
  undefinedCurrency: (
    <Trans
      i18nKey="alert.undefinedCurrency"
      defaults="Configured currency is undefined"
    />
  ),
});

export type TransAlertKeys = TransKeys<typeof TransAlert>;
