import type { FC } from 'react';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import type { BookingItem } from '@/types/booking';
import { TransText } from '@/i18n/trans/text';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';

export type BookingItemRowProps = BookingItem & {
  icon: keyof typeof Icons;
};

const BookingItemRow: FC<BookingItemRowProps> = ({
  icon,
  description,
  amount,
  ...rest
}) => {
  const currency = useSelector(currencySelector);
  const IconComponent = Icons[icon];

  const renderTripInfo = () => {
    if ('originStopName' in rest && 'destinationStopName' in rest) {
      const { originStopName, destinationStopName } = rest;

      if (originStopName && destinationStopName) {
        return (
          <div className="flex items-center gap-1 text-neutral">
            <Typography variant="body2">{originStopName}</Typography>
            <Icons.vectorRight className="h-4 w-4" />
            <Typography variant="body2">{destinationStopName}</Typography>
          </div>
        );
      }
    }

    return null;
  };

  const renderPassengerInfo = () => {
    if ('passengerIndex' in rest) {
      const { passengerIndex, firstName, lastName } = rest;

      if (passengerIndex !== null && !firstName && !lastName) {
        return (
          <Typography variant="body2" className="text-neutral">
            <TransText
              i18nKey="passengerIndex"
              values={{ count: passengerIndex + 1 }}
            />
          </Typography>
        );
      } else if (firstName || lastName) {
        return (
          <div className="flex items-center gap-1 text-neutral">
            {firstName && <Typography variant="body2">{firstName}</Typography>}
            {lastName && <Typography variant="body2">{lastName}</Typography>}
          </div>
        );
      }
    }

    return null;
  };

  return (
    <div className="px-4 py-2">
      <div className="flex items-center justify-between">
        <div className="flex gap-1">
          <IconComponent className="text-primary" />
          <Typography variant="body1-bold">{description}</Typography>
        </div>

        <Typography variant="body2-bold" className="text-primary">
          {amount}
          {currency.symbol}
        </Typography>
      </div>
      {renderTripInfo()}
      {renderPassengerInfo()}
    </div>
  );
};

export default BookingItemRow;
