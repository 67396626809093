import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from '@/components/primitives/Sheet';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { Loadable } from '@/components/Loadable';
import { useSelector } from '@/store/utils';
import { addAncillaryLoadingSelector } from '@/features/loading/loadingSelectors';
import { TransText } from '@/i18n/trans/text';
import { Button } from '@/components/primitives/Button';

interface MobileAncillaryModalProps {
  children: ReactNode;
  isOverlayOpened: boolean;
  setIsOverlayOpened: Dispatch<SetStateAction<boolean>>;
}

export const MobileAncillaryModal: FC<MobileAncillaryModalProps> = ({
  isOverlayOpened,
  setIsOverlayOpened,
  children,
}) => {
  const loading = useSelector(addAncillaryLoadingSelector);

  return (
    <Sheet open={isOverlayOpened} onOpenChange={setIsOverlayOpened}>
      <SheetTrigger asChild>
        <Button
          data-testid="add-new-ancillary-button"
          variant="tertiary"
          size="medium"
        >
          <Icons.addCircle />
          <TransText i18nKey="addNewAncillary" />
        </Button>
      </SheetTrigger>
      <SheetContent side="bottom" className="rounded-t-2xl p-0">
        <Loadable loading={loading} overlay>
          <SheetHeader className="flex flex-row items-center gap-3 space-y-0 p-4 shadow">
            <Icons.arrowLeft onClick={() => setIsOverlayOpened(false)} />
            <Typography variant="subtitle">
              <TransText i18nKey="addNewAncillary" />
            </Typography>
          </SheetHeader>
          {children}
        </Loadable>
      </SheetContent>
    </Sheet>
  );
};
