import { configureStore } from '@reduxjs/toolkit';
import { persistedReducer } from '@/store/reducer';
import { persistStore } from 'redux-persist';

export { type RootState } from '@/store/reducer';

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
