import { type FC, useMemo } from 'react';
import type { LegDTO, AdmissionDTO } from '@/types/dto';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { format } from 'date-fns';
import { formatTime } from '@/utils/date-time';
import { formatDuration } from '@/utils/date-time';
import HalfCircle from '@/components/manage-booking/HalfCircle';
import ProductDetails from '@/components/manage-booking/ProductDetails';
import _uniq from 'lodash/uniq';
import { Badge } from '@/components/primitives/Badge';
import { cn } from '@/utils/cn';
import { TransText } from '@/i18n/trans/text';

interface LegCardProps {
  leg: LegDTO;
  admissions: Array<AdmissionDTO>;
}

const LegCard: FC<LegCardProps> = ({ leg, admissions }) => {
  const productDescription = admissions[0].productDescription;
  const passengerCount = useMemo(
    () =>
      admissions.reduce<Array<string>>(
        (passengerIds, admission) =>
          _uniq([...passengerIds, ...admission.passengerIds!]),
        []
      ).length,
    [admissions]
  );
  const admissionsAmount = useMemo(
    () =>
      // TODO: probably something is wrong here, because "sum" should be used
      admissions.reduce((sum, { ancillaries, price }) => {
        const ancillariesAmount = ancillaries?.reduce(
          (ancillariesAmount, ancillary) =>
            ancillariesAmount +
            (ancillary.price?.amount || 0) * (ancillary.amountOf || 1),
          0
        );

        return sum + (price?.amount || 0) + (ancillariesAmount || 0);
      }, 0),
    [admissions]
  );

  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const isAllRefunded = useMemo(() => {
    return admissions.every((admission) => admission.status === 'REFUNDED');
  }, [admissions]);

  return (
    <div
      className={cn(
        'my-3 flex flex-col rounded-lg bg-white text-dark laptop:flex-row',
        { 'bg-neutral-pale text-neutral': isAllRefunded }
      )}
    >
      <div className="flex flex-col px-3 pt-3 laptop:w-full laptop:flex-row-reverse laptop:pt-0">
        <div className="laptop:flex laptop:w-2/5 laptop:flex-col laptop:justify-between laptop:gap-10 laptop:py-2">
          <div className="flex items-center justify-between laptop:flex-row">
            <div className="flex gap-3">
              <p>Icon TBD</p>
              <div className="flex flex-row items-center gap-1 rounded-lg bg-primary-light px-2 py-1">
                <Icons.bus className="h-3 w-3" />
                <Typography variant="body2-bold">{leg.lineNumber}</Typography>
              </div>
            </div>
            <Typography variant="body2">
              {format(leg.departureTime!, 'EEE, dd MMM yyyy')}
            </Typography>
          </div>
          {isLaptopOrBigger && (
            <ProductDetails
              passengerCount={passengerCount}
              description={productDescription}
              admissionsAmount={admissionsAmount}
            />
          )}
        </div>
        {isLaptopOrBigger && (
          <div className="flex flex-col items-center">
            <HalfCircle direction="down" />
            <div className="w-[1px] flex-1 border-l border-dashed" />
            <HalfCircle direction="up" />
          </div>
        )}
        <div className="mt-3">
          {isAllRefunded && (
            <Badge variant="negative">
              <Icons.ticketReturn height={16} width={16} />
              <TransText i18nKey="refunded" />
            </Badge>
          )}
        </div>
        <div className="grid grid-cols-[auto,1fr,auto] gap-x-2 pt-3 laptop:w-3/5 laptop:grid-cols-[auto,auto,auto]">
          <Typography variant="times">
            {formatTime(leg.departureTime)}
          </Typography>
          <Typography
            variant="caption"
            className="flex items-center gap-0.5 text-nowrap"
          >
            <Icons.line /> {formatDuration(leg.duration)} <Icons.line />
          </Typography>
          <Typography variant="times">{formatTime(leg.arrivalTime)}</Typography>
          <Typography>{leg.originStop?.name}</Typography>
          <div />
          <Typography>{leg.destinationStop?.name}</Typography>
        </div>
      </div>
      {!isLaptopOrBigger && (
        <>
          <div className="flex flex-row items-center pb-1 pt-3">
            <HalfCircle direction="right" />
            <div className="h-[1px] w-full border border-dashed" />
            <HalfCircle direction="left" />
          </div>
          <ProductDetails
            passengerCount={passengerCount}
            description={productDescription}
            admissionsAmount={admissionsAmount}
          />
        </>
      )}
    </div>
  );
};

export default LegCard;
